import React, { useEffect, useMemo } from 'react';

import * as d3 from 'd3';
import { useLocale } from 'hooks/useLocale';
import { useTranslation } from 'react-i18next';
import { localizedNumber } from 'utils/i18n';

const dimensions = {
  width: 630,
  height: 200,
  margin: {
    top: 60,
    bottom: 40,
  },
};

const colors = ['#DDDEE9', '#97CFFF', '#5555C4', '#313391', '#FF8155'];

export type JourneyDataKeys =
  | 'coachee.m_coachee_who_were_in_invited'
  | 'coachee.m_coachee_who_were_in_matching'
  | 'coachee.m_coachee_who_were_in_matched'
  | 'coachee.m_coachee_who_were_in_active'
  | 'coachee.m_coachee_completed';

export type JourneyData = { [K in JourneyDataKeys]: number };

type CoacheeActivationJourneyChartProps = {
  data: JourneyData;
};

const CoacheeActivationJourneyChart = ({ data }: CoacheeActivationJourneyChartProps) => {
  const { t } = useTranslation('Dashboard');
  const locale = useLocale();

  const { width, height, margin } = dimensions;
  const svgWidth = width;
  const svgHeight = height + margin.top + margin.bottom;

  const keys = Object.keys(data || {});
  let tData: { status: string; value: number; color: string; percentPrevious?: string }[] = useMemo(() => [], []);
  let lastValue = 0;

  for (const [i, key] of keys.entries()) {
    tData.push({
      status: key,
      value: (data as any)[key],
      color: colors[i],
      percentPrevious: i > 0 ? (((data as any)[key] * 100) / lastValue).toFixed(1) : undefined,
    });
    lastValue = (data as any)[key];
  }

  const maxValue = Math.max(...tData.map((d) => d.value));

  const svgRef = React.useRef(null);

  useEffect(() => {
    const LegendNames = {
      'coachee.m_coachee_who_were_in_invited': t('overview.engagement.coachee_activation.legend.invited'),
      'coachee.m_coachee_who_were_in_matching': t('overview.engagement.coachee_activation.legend.matching'),
      'coachee.m_coachee_who_were_in_matched': t('overview.engagement.coachee_activation.legend.matched'),
      'coachee.m_coachee_who_were_in_active': t('overview.engagement.coachee_activation.legend.active'),
      'coachee.m_coachee_completed': t('overview.engagement.coachee_activation.legend.completed'),
    };

    // Clear svg content before adding new elements
    const svgEl = d3.select(svgRef.current);
    svgEl.selectAll('*').remove();
    const svg = svgEl.append('g').attr('transform', `translate(0,${margin.top})`);
    // Scales
    const y = d3.scaleLinear().domain([0, maxValue]).range([height, 0]);

    // Chart Bar
    svg
      .selectAll('mybar')
      .data(tData)
      .enter()
      .append('rect')
      .attr('x', (d, i) => {
        return i * 140;
      })
      .attr('y', (d) => {
        return y(d.value);
      })
      .attr('width', 70)
      .attr('height', (d) => {
        return height - y(d.value) - 1;
      })
      .attr('fill', (d) => d.color)
      .attr('rx', '4');
    // Bar Value
    svg
      .selectAll('.bar-title')
      .data(tData)
      .enter()
      .append('text')
      .classed('bar-title', true)
      .attr('text-anchor', 'middle')
      .attr('x', (d, i) => i * 140 + 32)
      .attr('y', (d) => y(d.value) - 5)
      .style('fill', '#81839C')
      .attr('font-family', 'Montserrat')
      .attr('font-size', '12px')
      .attr('font-weight', '700')
      .attr('line-height', '16px')
      .attr('letter-spacing', '0px')
      .attr('text-align', 'left')
      .text((d) => `${d.value}`);
    // Bottom Line
    svg
      .append('line')
      .style('stroke', '#DDDEE9')
      .style('stroke-width', 1.5)
      .attr('x1', 0)
      .attr('y1', height)
      .attr('x2', width)
      .attr('y2', height);
    // Arrow
    const arrowData = tData.filter((d) => d.percentPrevious);
    svg
      .selectAll('.arrow')
      .data(arrowData)
      .enter()
      .append('svg')
      .classed('arrow', true)
      .attr('y', height / 2.3)
      .attr('x', (d, i) => i * 140 + 95)
      .attr('width', 23)
      .attr('height', 23)
      .attr('viewBox', '0 0 23 23')
      .append('path')
      .style('fill', '#313391')
      .attr(
        'd',
        'M22.1067 10.8192C22.0214 10.5992 21.8935 10.3983 21.7304 10.2279L12.7721 1.26959C12.605 1.10254 12.4067 0.970025 12.1884 0.879617C11.9702 0.789209 11.7362 0.742676 11.5 0.742676C11.0229 0.742676 10.5653 0.932213 10.2279 1.26959C10.0609 1.43664 9.92835 1.63496 9.83794 1.85323C9.74753 2.07149 9.701 2.30543 9.701 2.54167C9.701 3.0188 9.89054 3.47638 10.2279 3.81376L16.1404 9.70834H2.54167C2.06649 9.70834 1.61077 9.89711 1.27477 10.2331C0.938765 10.5691 0.75 11.0248 0.75 11.5C0.75 11.9752 0.938765 12.4309 1.27477 12.7669C1.61077 13.1029 2.06649 13.2917 2.54167 13.2917H16.1404L10.2279 19.1863C10.06 19.3528 9.9267 19.551 9.83574 19.7693C9.74478 19.9876 9.69794 20.2218 9.69794 20.4583C9.69794 20.6949 9.74478 20.929 9.83574 21.1474C9.9267 21.3657 10.06 21.5639 10.2279 21.7304C10.3945 21.8984 10.5926 22.0316 10.811 22.1226C11.0293 22.2136 11.2635 22.2604 11.5 22.2604C11.7365 22.2604 11.9707 22.2136 12.189 22.1226C12.4074 22.0316 12.6055 21.8984 12.7721 21.7304L21.7304 12.7721C21.8935 12.6017 22.0214 12.4008 22.1067 12.1808C22.2859 11.7446 22.2859 11.2554 22.1067 10.8192Z'
      );
    svg
      .selectAll('.arrow-title')
      .data(arrowData)
      .enter()
      .append('text')
      .classed('arrow-title', true)
      .attr('text-anchor', 'middle')
      .attr('x', (d, i) => i * 140 + 110)
      .attr('y', height / 2.5)
      .style('fill', '#313391')
      .attr('font-family', 'Montserrat')
      .attr('font-size', '12px')
      .attr('font-weight', '700')
      .attr('line-height', '16px')
      .attr('letter-spacing', '0px')
      .attr('text-align', 'left')
      .text(
        // Using localizedNumber to format comma separator and percentage value translation
        (d) => localizedNumber({ locale, value: Number(d.percentPrevious) / 100, options: { style: 'percent' } })
      );
    // Legend

    // first position of X
    // can't be 0 otherwise circle will be half cut
    let currentX = 12;

    const legendContainer = svg.append('g').attr('layout-css', 'flex:1; flexDirection: row');

    for (const [i, key] of keys.entries()) {
      legendContainer
        .append('circle')
        .attr('cx', currentX)
        .attr('cy', height + 25)
        .attr('id', `legend-circle-${i}`)
        .attr('r', 6)
        .style('fill', colors[i]);
      const text = legendContainer
        .append('text')
        .attr('id', `legend-text-${i}`)
        // we add 12 pixel that is 6px of marging + 6px of halfCircle
        .attr('x', currentX + 12)
        .attr('y', height + 25)
        .text(LegendNames[key as JourneyDataKeys])
        .style('font-size', '15px')
        .style('fill', '#81839C')
        .attr('font-family', 'Montserrat')
        .attr('font-size', '12px')
        .attr('font-weight', '400')
        .attr('line-height', '16px')
        .attr('letter-spacing', '0px')
        .attr('text-align', 'left')
        .attr('alignment-baseline', 'middle');

      // We add size of text to the next positionX
      // 24 is corresponding to 6pixel of marging x 2 + 12pixel of circle size
      currentX = currentX + 24 + (text.node()?.getBBox().width ?? 0);
    }

    // We will calculcate space available to put it equal between each legend
    const widthAvailable = width - currentX;
    const addedSpace = Math.floor(widthAvailable / (keys.length - 1));

    for (const [i] of keys.entries()) {
      if (i !== 0) {
        const XText = document.getElementById(`legend-text-${i}`)?.getAttribute('x');
        const XCircle = document.getElementById(`legend-circle-${i}`)?.getAttribute('cx');

        if (XText && XCircle) {
          document.getElementById(`legend-text-${i}`)?.setAttribute('x', `${parseInt(XText) + addedSpace * i}`);
          document.getElementById(`legend-circle-${i}`)?.setAttribute('cx', `${parseInt(XCircle) + addedSpace * i}`);
        }
      }
    }
  }, [data, height, keys, margin.top, maxValue, tData, width, locale, t]);

  return <svg ref={svgRef} width={svgWidth} height={svgHeight} />;
};

export default CoacheeActivationJourneyChart;
