import { FaceHappyIcon, FaceSadIcon } from '@coachhubio/nova-icon';
import { gap, marginBottom, marginStart, marginTop, paddingTop } from '@coachhubio/nova-spaces';
import { BrandTag, Card, CenteredColumn, CenteredText, Column, Row } from 'screens/Dashboard/components/Common';
import styled from 'styled-components';

export const HeaderWrapper = styled(CenteredColumn)`
  ${marginBottom('s')}
`;

export const RowWithGap = styled(Row)`
  ${gap('s')}
`;

export const ColumnWithGap = styled(Column)`
  ${gap('s')}
`;

export const HappyIcon = styled(FaceHappyIcon)`
  height: 44px;
  width: 44px;

  ${marginStart('s')}
`;

export const SadIcon = styled(FaceSadIcon)`
  height: 44px;
  width: 44px;

  ${marginStart('s')}
`;

export const CircularProgressCard = styled(Card)`
  flex-basis: 220px;
  flex-shrink: 0;

  ${CenteredColumn} {
    ${paddingTop('base')}
  }

  ${CenteredText} {
    ${marginTop('base')}
  }

  ${BrandTag} {
    ${marginTop('l')}
  }
`;
