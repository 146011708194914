import React, { useRef, useState } from 'react';

interface IframeProps {
  /**
   * Defaults to 100%
   */
  width?: string;

  /**
   * Iframe height is dynamically calculated if `height` is not set. If the dynamic calculation fails, it will fallback to 1500px.
   */
  height?: string;
  url: string;
  className?: string;
}

export function Iframe({ url, width, height, className }: IframeProps) {
  const [iframeHeight, setIframeHeight] = useState<string>(`${height || 1500}px`);
  const iframeEl = useRef(null);

  return (
    <iframe
      title={url}
      className={className}
      ref={iframeEl}
      onLoad={() => {
        if (height) {
          return;
        }

        let internalIframeHeight: number | undefined;

        try {
          internalIframeHeight = ((iframeEl.current as any) as HTMLIFrameElement).contentWindow?.document?.body
            ?.scrollHeight;
        } catch (err) {
          console.warn('Could not determine iframe height.');
        }

        setIframeHeight(`${internalIframeHeight || 1500}px`);
      }}
      frameBorder="0"
      scrolling="no"
      allowFullScreen
      width={width || '100%'}
      height={iframeHeight}
      src={url}
    ></iframe>
  );
}
