import { useEffect, useState } from 'react';

import { useLooker } from 'context/LookerContext';
import { getData, FiltersData } from 'utils/fetchDashboard';

function useGetLookerData<T>(
  lookerTag: string,
  customFilters?: FiltersData | undefined,
  extrasFilters?: Record<string, string | string[]>
): { data: T | undefined; loading: boolean } {
  const { looker, filters: globalFilters } = useLooker();
  const [data, setLookerData] = useState<T | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const filters = customFilters || globalFilters;

  useEffect(() => {
    let isStale = false;
    const getLookerData = async (
      lookerConfig: any,
      filtersAPI: FiltersData,
      lookerTag: string,
      extrasFiltersAPI?: Record<string, string | string[]>
    ): Promise<void> => {
      setLookerData(undefined);
      const data = (await getData(lookerTag, lookerConfig, filtersAPI, undefined, extrasFiltersAPI)) as T;
      if (!isStale) {
        setLoading(false);
        setLookerData(data);
      }
    };

    if (looker && filters && lookerTag) {
      setLoading(true);
      getLookerData(looker, filters, lookerTag, extrasFilters);
    }
    return () => {
      isStale = true;
    };
  }, [looker, filters, lookerTag, extrasFilters]);

  return {
    data,
    loading,
  };
}

export function useGetLookerSortedFeedbackData<T>(lookerTag: string): { data: T | undefined; loading: boolean } {
  const { looker, filters, sorts, score } = useLooker();
  const [data, setLookerData] = useState<T | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let isStale = false;

    const getLookerData = async (
      lookerConfig: any,
      filtersAPI: FiltersData,
      lookerTag: string,
      sorts: string[],
      extraFilters: { [key: string]: string | string[] }
    ): Promise<void> => {
      setLookerData(undefined);
      const data = (await getData(lookerTag, lookerConfig, filtersAPI, sorts, extraFilters)) as T;
      if (!isStale) {
        setLoading(false);
        setLookerData(data);
      }
    };

    if (looker && filters && lookerTag) {
      setLoading(true);
      getLookerData(looker, filters, lookerTag, sorts || [], { avg_score: score?.toString() || '' });
    }

    return () => {
      isStale = true;
    };
  }, [looker, filters, lookerTag, sorts, score]);

  return {
    data,
    loading,
  };
}

export default useGetLookerData;
