import React from 'react';

import { ArrowRightIcon } from '@coachhubio/nova-icon';
import { Skeleton } from '@coachhubio/nova-skeleton';
import { padding, gap } from '@coachhubio/nova-spaces';
import { Tag } from '@coachhubio/nova-tag';
import { useCoachingLicensesRef } from 'context/CoachingLicensesRef';
import { useLooker } from 'context/LookerContext';
import { KEYS, useCoachingMonthsForLicenses } from 'hooks/useCoachingMonthsForLicenses';
import { useValidateImageURL, Status } from 'hooks/useValidateImageURL';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Row, Placeholder, Title, ClickableTag } from './Common';

const LicenseCount = styled(Row)`
  align-items: center;

  ${gap('m')}
`;

const Logo = styled.img`
  height: 50px;

  ${padding('xs')}
`;

export function DashboardTitle() {
  const { t } = useTranslation('Dashboard');
  const { organizationData } = useLooker();
  const { loading, data } = useCoachingMonthsForLicenses();

  const count = data?.[0]?.[KEYS.TOTAL];
  const imageStatus = useValidateImageURL(organizationData?.logo);
  const { ref } = useCoachingLicensesRef();
  return (
    <Title data-testid="head-section">
      {organizationData ? (
        <>
          <LicenseCount data-hj-suppress>
            {`${organizationData.name}`}
            {loading ? (
              <Placeholder $height="16px" $width="130px">
                <Skeleton height="16px" width="130px" />
              </Placeholder>
            ) : count !== undefined ? (
              <>
                {ref?.current && (
                  <ClickableTag
                    variant="hint"
                    iconPosition="end"
                    Icon={ArrowRightIcon}
                    onClick={() =>
                      ref?.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })
                    }
                  >
                    {t(`common.license_left`, {
                      count: count,
                    })}
                  </ClickableTag>
                )}
              </>
            ) : (
              <Tag variant="hint">{t(`common.no_data`)}</Tag>
            )}
          </LicenseCount>
          {organizationData?.logo && imageStatus === Status.VALID ? (
            <Logo src={organizationData.logo} alt={`${organizationData.name} logo`} data-hj-suppress />
          ) : null}
        </>
      ) : (
        <Placeholder $height="30px" $width="430px">
          <Skeleton height="30px" width="430px" />
        </Placeholder>
      )}
    </Title>
  );
}
