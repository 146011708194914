import React from 'react';

import { getSingleBorder } from '@coachhubio/nova-borders';
import { getBackgroundColor } from '@coachhubio/nova-colors';
import { Skeleton } from '@coachhubio/nova-skeleton';
import { gap, paddingY } from '@coachhubio/nova-spaces';
import { Text } from '@coachhubio/nova-text';
import { useTranslation } from 'react-i18next';
import { Row, InlinePlaceholder } from 'screens/Dashboard/components/Common';
import styled from 'styled-components';

import GoalPercentageGraph from '../../components/GoalPercentageGraph';
import { ImpactFocusAreaProgressByCategory } from './TopCategories';

const Container = styled.div`
  ${gap('s')}
  ${paddingY('m')}
  ${getSingleBorder({ color: 'neutral80', position: 'top', width: 's' })}

  display: grid;
`;

const Card = styled.div`
  grid-template-columns: 1fr 1fr;
  display: grid;
`;

const TextContainer = styled.div`
  ${getBackgroundColor('brandDark')}

  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const LeftSideRow = styled(Row)`
  ${gap('m')}

  align-items: center;
`;

const RightSideRow = styled(Row)`
  justify-content: flex-end;
`;

type Props = {
  isLoading: boolean;
  category: ImpactFocusAreaProgressByCategory;
  row?: number;
};
const CategoryRow = ({ isLoading, category, row = undefined }: Props) => {
  const { t } = useTranslation('Dashboard');

  const percentage = ((category?.goal_attainment || 0) * 100).toFixed();

  return (
    <Container>
      {isLoading ? (
        <InlinePlaceholder $height="44px" $width="100%">
          <Skeleton height="44px" width="100%" />
        </InlinePlaceholder>
      ) : category?.row ? (
        <Card>
          <LeftSideRow>
            <TextContainer>
              <Text modifier="bold" size="s" color="neutral">
                {!!row ? row : category?.row}
              </Text>
            </TextContainer>
            <Text color="primaryDark35" modifier="bold">
              {t(category?.['focus_areas.categorytext'].replace(/::/g, '.'))}
            </Text>
          </LeftSideRow>
          <RightSideRow>
            <GoalPercentageGraph progressPercentage={+percentage} arcWidth={4} svgWidth={32} />
          </RightSideRow>
        </Card>
      ) : (
        <Text color="primaryDark35" size="m">
          {t('common.no_data')}
        </Text>
      )}
    </Container>
  );
};

export default CategoryRow;
