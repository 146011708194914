import React from 'react';

import { paddingTop } from '@coachhubio/nova-spaces';
import { Card, GraphsContainer, Column } from 'screens/Dashboard/components/Common';
import styled from 'styled-components';

import AssignmentCompletionRate from './AssignmentCompletionRate';
import TopAssignmentsList from './TopAssignmentsList';

const SCard = styled(Card)`
  height: fit-content;
`;

const Container = styled(Column)`
  ${paddingTop('l')}
`;

const LearningBottomSection = () => {
  return (
    <Container>
      <GraphsContainer>
        <Card>
          <TopAssignmentsList />
        </Card>
        <SCard>
          <AssignmentCompletionRate />
        </SCard>
      </GraphsContainer>
    </Container>
  );
};

export default LearningBottomSection;
