import React from 'react';

import { Button } from '@coachhubio/nova-button';
import { getColor } from '@coachhubio/nova-colors';
import { Select, Option, SelectValue } from '@coachhubio/nova-select';
import { Skeleton } from '@coachhubio/nova-skeleton';
import { marginBottom, marginEnd, marginTop, marginStart } from '@coachhubio/nova-spaces';
import { Tag } from '@coachhubio/nova-tag';
import { Text } from '@coachhubio/nova-text';
import { useFlags } from 'hooks/useFlags';
import useGetLookerData from 'hooks/useGetLookerData';
import useLookerFilter, { FilterName, SingleSelectFilter } from 'hooks/useLookerFilters';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { LookerFieldsTag } from '../utils/lookerFields';
import { Row, Column, Placeholder } from './Common';

const FiltersCol = styled(Column)`
  flex-grow: 0;
`;

const FiltersRow = styled(Row)`
  ${marginTop('base')}
`;

const ButtonRow = styled(Row)`
  width: 100%;

  ${marginTop('s')};
  ${marginBottom('xs')}
`;

const CoacheesSelectedRow = styled(Row)`
  align-self: center;

  ${marginStart('s')}
`;

const FiltersContainer = styled(Row)`
  flex-grow: 0;
  flex-wrap: wrap;

  gap: 10px;

  ${marginEnd('m')}
`;

const StyledSelect = styled(Select)<{ value?: string | string[] }>`
  & ${SelectValue} {
    ${({ value }) => (!value || value.length === 0 ? getColor('neutral60') : '')}
  }
`;

const StyledText = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`;

type CoacheesSelected = {
  'coachee.m_coachees': number;
};

export function Filters() {
  const { t } = useTranslation('Dashboard');
  const { loading, data = [] } = useGetLookerData<CoacheesSelected[]>(LookerFieldsTag.CoacheesSelected);
  const coacheesCount = data[0]?.['coachee.m_coachees'];
  const { enableCoacheeList } = useFlags();

  const { currentFilters, prevFilters, updateFilter, handleFilterClick, filtersOptions } = useLookerFilter();

  const SelectPlaceholder = t('common.filters.placeholder');

  const nameMap: { [key in FilterName]: string } = {
    organizations: t('common.filters.businessunit'),
    programs: t('common.filters.program'),
    genders: t('common.filters.gender.label'),
    benchmark_types: t('common.filters.benchmark.label'),
    career_levels: t('common.filters.career_level'),
    countries: t('common.filters.countries'),
    data_range: t('common.filters.data_range'),
  };

  const hasNewFilters = JSON.stringify(currentFilters) !== JSON.stringify(prevFilters);

  return (
    <FiltersCol>
      <FiltersRow>
        <FiltersContainer>
          {filtersOptions ? (
            <>
              {Object.entries(nameMap).map(([filter, name]) => {
                const options = filtersOptions[filter as FilterName];
                const value = currentFilters[filter as FilterName];
                const onChange = updateFilter(filter as FilterName) as typeof filter extends SingleSelectFilter
                  ? (value: string) => void
                  : (value: string[]) => void;

                return (
                  <StyledSelect
                    value={value as typeof filter extends SingleSelectFilter ? string : string[]}
                    onChange={onChange}
                    placeholder={SelectPlaceholder}
                    label={name}
                    key={filter}
                    size="xs"
                    itemsSelectedMessage={t('common.filters.selected')}
                    data-testid={`filter-${filter}`}
                    dropdownWidthAuto
                  >
                    {options.map((option, index) => (
                      <Option value={option.value} title={t(option.label)} key={`${option.label}-${index}`} />
                    ))}
                  </StyledSelect>
                );
              })}
            </>
          ) : (
            <Placeholder $height="68px" $width="700px">
              <Skeleton height="68px" width="700px" />
            </Placeholder>
          )}
        </FiltersContainer>
      </FiltersRow>
      <ButtonRow>
        <Button variant="secondary" size="s" disabled={!hasNewFilters} onClick={handleFilterClick}>
          {t('common.filters.apply')}
        </Button>
        <CoacheesSelectedRow>
          {loading ? (
            <Placeholder $height="16px" $width="130px">
              <Skeleton height="16px" width="130px" />
            </Placeholder>
          ) : (
            <>
              {enableCoacheeList &&
                (coacheesCount !== undefined ? (
                  <Link href="/coachee-list">
                    <StyledText size="s" modifier="bold" color="secondaryDark55">
                      {t(`common.coachees_selected`, { count: coacheesCount })}
                    </StyledText>
                  </Link>
                ) : (
                  <Tag variant="hint">{t('common.no_data')}</Tag>
                ))}
            </>
          )}
        </CoacheesSelectedRow>
      </ButtonRow>
    </FiltersCol>
  );
}
