import React from 'react';

import { paddingTop } from '@coachhubio/nova-spaces';
import { Card, GraphsContainer, ColumnWithoutFlex } from 'screens/Dashboard/components/Common';
import styled from 'styled-components';

import TopCategories from './TopCategories';
import TopFocusAreas from './TopFocusAreas';

const Container = styled(ColumnWithoutFlex)`
  ${paddingTop('l')}
`;

const ProgressTowardsGoalsSection = () => {
  return (
    <Container>
      <GraphsContainer>
        <Card>
          <TopCategories />
        </Card>
        <Card>
          <TopFocusAreas />
        </Card>
      </GraphsContainer>
    </Container>
  );
};

export default ProgressTowardsGoalsSection;
