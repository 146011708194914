import React from 'react';

import { ColorAlias, getFillColor } from '@coachhubio/nova-colors';
import { gap } from '@coachhubio/nova-spaces';
import { Text } from '@coachhubio/nova-text';
import * as d3 from 'd3';
import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  align-items: center;
  justify-content: flex-end;
  grid-template-columns: 1fr 50px;
  ${gap('m')}
`;

const Path = styled.path<{ $fillColor: ColorAlias }>`
  ${({ $fillColor }) => getFillColor($fillColor)};
`;

type Props = {
  svgWidth: number;
  arcWidth: number;
  progressPercentage: number;
  bgColor?: ColorAlias;
  progressionColor?: ColorAlias;
};

function GoalPercentageGraph(props: Props) {
  const { svgWidth, arcWidth, progressPercentage, progressionColor = 'primaryDark35', bgColor = 'neutral80' } = props;
  const svgHeight = svgWidth;
  const arcOuterRadius = svgWidth / 2;
  const arcInnerRadius = svgWidth / 2 - arcWidth;
  const arcGenerator = d3.arc().innerRadius(arcInnerRadius).outerRadius(arcOuterRadius).startAngle(0).cornerRadius(5);

  const progressArc = (value: number) =>
    arcGenerator({
      endAngle: 2 * Math.PI * value,
    } as any) as string;

  return (
    <Container>
      <svg height={svgHeight} width={svgWidth}>
        <g transform={`translate(${svgWidth / 2}, ${svgHeight / 2})`}>
          <Path d={progressArc(1)} opacity="0.2" $fillColor={bgColor} />
        </g>
        <g transform={`translate(${svgWidth / 2}, ${svgHeight / 2})`}>
          <Path d={progressArc(progressPercentage / 100)} $fillColor={progressionColor} />
        </g>
      </svg>
      <Text modifier="bold" color="primaryDark35">
        {`${progressPercentage} %`}
      </Text>
    </Container>
  );
}

export default GoalPercentageGraph;
