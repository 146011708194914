import React from 'react';

import LookerDashboardLayout from '../components/LookerDashboardLayout';
import { CoacheeExperience, CoacheeFeedback } from './components';

export function LookerSatisfactionDashboard() {
  return (
    <LookerDashboardLayout>
      <CoacheeExperience />
      <CoacheeFeedback />
    </LookerDashboardLayout>
  );
}
