import React from 'react';

import { InfoIcon } from '@coachhubio/nova-icon';
import { Skeleton } from '@coachhubio/nova-skeleton';
import { paddingBottom, paddingY } from '@coachhubio/nova-spaces';
import { gap } from '@coachhubio/nova-spaces';
import { Tag } from '@coachhubio/nova-tag';
import { Text } from '@coachhubio/nova-text';
import useGetLookerData from 'hooks/useGetLookerData';
import { useTranslation } from 'react-i18next';
import { Row, Column, InlinePlaceholder, BrandTag, SubTitle } from 'screens/Dashboard/components/Common';
import { LookerFieldsTag } from 'screens/Dashboard/utils/lookerFields';
import styled from 'styled-components';

import { useLocale } from '../../../../hooks/useLocale';
import { localizedNumber } from '../../../../utils/i18n';
import { CircularProgress } from '../../components/CircularProgressionGraph';

const TRow = styled(Row)`
  ${gap('m')}
  display: grid;
  grid-template-columns: 140px 1fr;

  justify-content: center;
  align-items: center;
`;
const InfoColumn = styled(Column)`
  width: 100%;
  ${gap('s')}
  ${paddingY('l')}
`;

const SColumn = styled(Column)`
  ${gap('s')}
  ${paddingBottom('l')}
`;

const GraphCol = styled(Column)`
  width: 100%;
  justify-content: center;
  align-items: center;
`;

type LearningAcademyCompletionRateLooker = {
  benchmark: number;
  coachees_assigned_to: number;
  of_coachees_completed: number;
  completion_rate: number;
  not_completion_rate: number;
  coachees_assigned_to_all: number;
};

const AssignmentCompletionRate = () => {
  const { t } = useTranslation('Dashboard');
  const locale = useLocale();

  const { loading, data = [] } = useGetLookerData<LearningAcademyCompletionRateLooker[]>(
    LookerFieldsTag.AssignmentCompletionRate
  );

  const academyCompletionRate = data[0];

  return (
    <Column>
      <SColumn>
        <SubTitle>{t('engagement.academy.completion.title')}</SubTitle>
        <Tag iconPosition="start" variant="hint" Icon={InfoIcon}>
          {t('overview.engagement.avg_sessions.info')}
        </Tag>
      </SColumn>
      <TRow>
        <GraphCol>
          {loading ? (
            <InlinePlaceholder $height="156px" $width="156px">
              <Skeleton height="156px" width="156px" />
            </InlinePlaceholder>
          ) : academyCompletionRate?.coachees_assigned_to > 0 ? (
            <CircularProgress
              fontSize="xl"
              size="156px"
              bgColor="neutral80"
              color="tertiaryDark80"
              value={+academyCompletionRate?.completion_rate?.toFixed()}
              valueFormatter={localizedNumber({
                locale,
                value: +academyCompletionRate?.completion_rate?.toFixed() / 100,
                options: { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 },
              })}
            />
          ) : (
            <Text>{t('common.no_data')}</Text>
          )}
        </GraphCol>
        <InfoColumn>
          {loading ? (
            <InlinePlaceholder $height="76px" $width="100%">
              <Skeleton height="76px" width="100%" />
            </InlinePlaceholder>
          ) : academyCompletionRate ? (
            <>
              <Text color="text30" size="m" modifier="bold">
                {t('engagement.academy.completion.legend')}
              </Text>
              <Text color="text30" size="s">
                {t('engagement.academy.completion.description', {
                  count2: academyCompletionRate?.coachees_assigned_to,
                  count1: academyCompletionRate?.of_coachees_completed,
                })}
              </Text>
            </>
          ) : (
            <BrandTag variant="hint">{t('common.no_data')}</BrandTag>
          )}
          {loading ? (
            <InlinePlaceholder $height="16px" $width="120px">
              <Skeleton height="16px" width="120px" />
            </InlinePlaceholder>
          ) : academyCompletionRate?.benchmark ? (
            <BrandTag>
              {t('overview.satisfaction.avg_session.benchmark', {
                number: academyCompletionRate.benchmark,
              })}{' '}
              %
            </BrandTag>
          ) : (
            <BrandTag variant="hint">{t('common.no_data')}</BrandTag>
          )}
        </InfoColumn>
      </TRow>
    </Column>
  );
};

export default AssignmentCompletionRate;
