import React from 'react';

import LookerDashboardLayout from '../components/LookerDashboardLayout';
import { EngagementSection, GraphRow } from './components/EngagementSection';
import { GraphImpact } from './components/GraphImpact';
import { GraphSatisfaction } from './components/GraphSatisfaction';

export function LookerOverviewDashboard() {
  return (
    <LookerDashboardLayout>
      <EngagementSection />
      <GraphRow>
        <GraphImpact />
        <GraphSatisfaction />
      </GraphRow>
    </LookerDashboardLayout>
  );
}
