import React, { useEffect, useState } from 'react';

import { getBorder } from '@coachhubio/nova-borders';
import { getSingleBorder } from '@coachhubio/nova-borders';
import { Button } from '@coachhubio/nova-button';
import { ArrowDownIcon, ArrowUpIcon } from '@coachhubio/nova-icon';
import { Skeleton } from '@coachhubio/nova-skeleton';
import { marginY, marginEnd, marginTop, padding, marginX, gap } from '@coachhubio/nova-spaces';
import { Tag } from '@coachhubio/nova-tag';
import { Text } from '@coachhubio/nova-text';
import { getTextStyle, lineHeight } from '@coachhubio/nova-typography';
import { useLooker } from 'context/LookerContext';
import { isString } from 'lodash';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { DynamicIcon } from 'screens/Dashboard/components/DynamicIcon';
import styled from 'styled-components';
import { getData, FiltersData } from 'utils/fetchDashboard';

import { Row, Column, Placeholder, SubTitle, Description, CenteredRow } from '../../components/Common';

const GraphCoachImpact = styled.div`
  display: flex;
  width: 100%;

  ${padding('l')}
  ${getBorder({ color: 'neutral80', width: 's', radius: 'm' })}

  @media (min-width: 1390px) {
    width: 50%;
  }
`;

const Separator = styled.div`
  height: 200px;
  width: 0px;

  ${marginTop('2xl')}
  ${getSingleBorder({ color: 'neutral80', position: 'start', width: 's' })}
`;

const GraphRowImpact = styled(Row)`
  justify-content: space-between;

  ${marginTop('l')}
  ${marginX('s')}
  ${gap('xl')}
`;

const FocusAreaRow = styled(Row)`
  height: 40px;
  align-items: center;

  ${marginY('s')}
`;

const FocusAreaLabel = styled(Text)`
  max-width: 60%;

  ${getTextStyle('text', 's', 'bold')}
  ${lineHeight('s')}
`;

const FocusAreaImg = styled(DynamicIcon)`
  ${marginEnd('s')}
`;

const SeeMoreButton = styled(Button)`
  align-self: center;

  ${marginTop('l')}
`;

type ImpactProgress = {
  average_of_first_rating: number;
  average_of_last_rating: number;
  'focus_areas.icon': string;
  'focus_areas.title': string;
  progress_made: number;
  row: number;
};

export function GraphImpact() {
  const { t } = useTranslation('Dashboard');
  const { filters, looker } = useLooker();

  const router = useRouter();

  const [errorCount, setCountError] = useState(false);

  const [impactMostProgress, setMostProg] = useState<any>(undefined);
  const [impactLeastProgress, setLeastProg] = useState<any>(undefined);
  const [impactRateCount, setRating] = useState<any>(undefined);

  useEffect(() => {
    const fetchDataImpactMost = async (looker: any, filters: FiltersData): Promise<void> => {
      const mostProg = await getData('overview_impact_most_progress', looker, filters);

      setMostProg(mostProg?.slice(0, 3) || []);
    };

    const fetchDataImpactLeast = async (looker: any, filters: FiltersData): Promise<void> => {
      const leastProg = await getData('overview_impact_least_progress', looker, filters);

      setLeastProg(leastProg?.slice(0, 3) || []);
    };

    const fetchDataRated = async (looker: any, filters: FiltersData): Promise<void> => {
      const count = await getData('overview_impact_coachees_rated', looker, filters);
      const coacheeCount = count?.[0]?.['count_of_coacheeid'] ?? 0;

      coacheeCount ? setRating(parseInt(coacheeCount)) : setCountError(true);
    };

    setCountError(false);
    setRating(undefined);
    setLeastProg(undefined);
    setMostProg(undefined);

    if (looker && filters) {
      fetchDataImpactMost(looker, filters);
      fetchDataImpactLeast(looker, filters);
      fetchDataRated(looker, filters);
    }
  }, [looker, filters]);

  return (
    <GraphCoachImpact>
      <Column>
        <SubTitle>{t('overview.impact.title')}</SubTitle>
        <Description color="primaryDark25" size="s">
          {t('overview.impact.description')}
        </Description>
        {!errorCount ? (
          <>
            {impactRateCount !== undefined ? (
              <Tag variant="hint">
                {t('overview.impact.coachees_rated', {
                  count: impactRateCount,
                })}
              </Tag>
            ) : (
              <Placeholder $height="16px" $width="350px">
                <Skeleton height="16px" width="350px" />
              </Placeholder>
            )}
          </>
        ) : (
          <Tag variant="hint">{t(`common.no_data`)}</Tag>
        )}
        <GraphRowImpact>
          <Column>
            <Row>
              <ArrowUpIcon color="success35" />
              <FocusAreaLabel color="primaryDark25">{t(`overview.impact.most_progress`)}</FocusAreaLabel>
            </Row>
            {impactMostProgress ? (
              (impactMostProgress as ImpactProgress[]).map((focusArea, index) => {
                const { 'focus_areas.icon': areaIcon, 'focus_areas.title': areaTitle } = focusArea;

                return isString(areaIcon) && isString(areaTitle) ? (
                  <FocusAreaRow key={`${areaTitle}-${index}`}>
                    <FocusAreaImg src={areaIcon} alt={`${areaTitle} Focus Area Icon`} width={40} height={40} />
                    <Text color="primaryDark25" size="s">
                      {t(areaTitle.replace(/::/g, '.'))}
                    </Text>
                  </FocusAreaRow>
                ) : null;
              })
            ) : (
              <Placeholder $height="170px" $width="100%">
                <Skeleton height="170px" width="100%" />
              </Placeholder>
            )}
          </Column>
          <Separator />
          <Column>
            <Row>
              <ArrowDownIcon color="error40" />
              <FocusAreaLabel color="primaryDark25">{t(`overview.impact.least_progress`)}</FocusAreaLabel>
            </Row>
            {impactLeastProgress ? (
              (impactLeastProgress as ImpactProgress[]).map((focusArea, index) => {
                const { 'focus_areas.icon': areaIcon, 'focus_areas.title': areaTitle } = focusArea;
                return isString(areaIcon) && isString(areaTitle) ? (
                  <FocusAreaRow key={`${areaTitle}-${index}`}>
                    <FocusAreaImg src={areaIcon} alt={`${areaTitle} Focus Area Icon`} width={40} height={40} />
                    <Text color="primaryDark25" size="s">
                      {t(areaTitle.replace(/::/g, '.'))}
                    </Text>
                  </FocusAreaRow>
                ) : null;
              })
            ) : (
              <Placeholder $height="170px" $width="100%">
                <Skeleton height="170px" width="100%" />
              </Placeholder>
            )}
          </Column>
        </GraphRowImpact>
        <CenteredRow>
          <SeeMoreButton onClick={() => router.push('/impact')} variant="secondary">
            {t('overview.impact.seemore')}
          </SeeMoreButton>
        </CenteredRow>
      </Column>
    </GraphCoachImpact>
  );
}
