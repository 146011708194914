import React from 'react';

import { getSingleBorder } from '@coachhubio/nova-borders';
import { InfoIcon, ArrowDownIcon, ArrowUpIcon } from '@coachhubio/nova-icon';
import { Skeleton } from '@coachhubio/nova-skeleton';
import { gap, paddingEnd, paddingStart, paddingTop } from '@coachhubio/nova-spaces';
import { Tag } from '@coachhubio/nova-tag';
import { Heading, Text } from '@coachhubio/nova-text';
import { useTranslation } from 'react-i18next';
import { Column, BrandTag, Row, InlinePlaceholder } from 'screens/Dashboard/components/Common';
import styled from 'styled-components';

const SessionCol = styled(Column)`
  ${gap('s')}
  ${paddingTop('m')}
  ${getSingleBorder({ color: 'neutral80', width: 's', position: 'start' })}
  ${paddingStart('s')}

  &:first-child {
    ${paddingStart('none')}
    ${paddingEnd('s')}
    ${getSingleBorder({ width: 'none', position: 'start' })}
  }
`;

const RowWithIcon = styled(Row)`
  & svg {
    width: 44px;
    height: 44px;
  }
`;

type Props = {
  i18description: string;
  count: string | number;
  isLoading?: boolean;
  average?: number;
  benchmark?: number;
  i18tag?: string;
};

const SessionScoreBar = ({
  benchmark = undefined,
  isLoading,
  i18description,
  i18tag = 'overview.engagement.avg_sessions.info',
  count,
  average = 0,
}: Props) => {
  const { t, i18n } = useTranslation('Dashboard');
  return (
    <SessionCol>
      <RowWithIcon>
        {isLoading ? (
          <InlinePlaceholder $height="44px" $width="100px">
            <Skeleton height="44px" width="100px" />
          </InlinePlaceholder>
        ) : (
          <>
            {count !== undefined ? (
              <>
                <Text color="secondaryDark55" size="xl" type="heading">
                  {new Intl.NumberFormat(i18n.language.replace('_', '-'), {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(typeof count === 'string' ? Number(count) : count)}
                </Text>
                {average >= 0 ? <ArrowUpIcon color="success35" /> : <ArrowDownIcon color="error40" />}
              </>
            ) : (
              <Heading color="primaryDark35" size="s">
                {t(`common.no_data`)}
              </Heading>
            )}
          </>
        )}
      </RowWithIcon>
      <Text size="s" modifier="bold" color="text30">
        {t(i18description)}
      </Text>
      {isLoading ? (
        <InlinePlaceholder $height="16px" $width="120px">
          <Skeleton height="16px" width="120px" />
        </InlinePlaceholder>
      ) : benchmark ? (
        <BrandTag>
          {t('overview.satisfaction.avg_session.benchmark', {
            number: new Intl.NumberFormat(i18n.language.replace('_', '-')).format(benchmark),
          })}
        </BrandTag>
      ) : (
        <BrandTag variant="hint">{t('common.no_data')}</BrandTag>
      )}
      <Tag iconPosition="start" variant="hint" Icon={InfoIcon}>
        {t(i18tag)}
      </Tag>
    </SessionCol>
  );
};

export default SessionScoreBar;
