import React, { useEffect, useState } from 'react';

type DynamicIconProps = {
  src: string;
  alt?: string;
  width: number;
  height: number;
};

export function DynamicIcon({ src, alt = '', width, height }: DynamicIconProps) {
  const [imageElement, setImageElement] = useState<any>(<img alt={alt} />);

  useEffect(() => {
    fetch(src)
      .then((response) => response.text())
      .then((svg) => {
        let blob = new Blob([svg], { type: 'image/svg+xml' });
        let url = URL.createObjectURL(blob);
        const image = React.createElement('img', {
          src: url,
          alt,
          width,
          height,
        });
        setImageElement(image);
      });
  }, [src, alt, height, width]);

  return imageElement;
}
