import { useMemo } from 'react';

import { Skeleton } from '@coachhubio/nova-skeleton';
import { Text } from '@coachhubio/nova-text';
import useGetLookerData from 'hooks/useGetLookerData';
import { useLocale } from 'hooks/useLocale';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from 'screens/Dashboard/components/CircularProgressionGraph';
import { BrandTag, CenteredColumn, CenteredText, Placeholder } from 'screens/Dashboard/components/Common';
import { LookerFieldsTag } from 'screens/Dashboard/utils/lookerFields';

import { localizedNumber } from '../../../../../utils/i18n';
import { CircularProgressCard } from './Styles';

type TaskProps = {
  max: number;
} & { [dataAttribute: `data-${string}`]: string };

type FeedbackTask = {
  'benchmark_fixed_period.benchmark_value': number;
  'coachee_sessions.m_avg_taskscore': number;
};

export default function Task({ max, ...props }: TaskProps) {
  const { t } = useTranslation('Dashboard');
  const locale = useLocale();

  const { loading, data } = useGetLookerData<FeedbackTask[]>(LookerFieldsTag.SatisfactionTaskScore);

  const [score, benchmark] = useMemo(() => {
    if (Array.isArray(data) && data.length > 0) {
      const { 'coachee_sessions.m_avg_taskscore': score, 'benchmark_fixed_period.benchmark_value': benchmark } =
        data[0] ?? {};
      return [score, benchmark].map((el) => (['number', 'string'].includes(typeof el) ? Number(el.toFixed(1)) : null));
    }
    return [null, null];
  }, [data]);

  return (
    <CircularProgressCard {...props}>
      <CenteredColumn>
        {!loading ? (
          <>
            {score ? (
              <CircularProgress
                value={score}
                max={max}
                bgColor="neutral85"
                color="tertiaryDark80"
                size="122px"
                valueFormatter={localizedNumber({
                  locale,
                  value: score,
                })}
                data-testid="coachee-task-score"
              />
            ) : (
              <Text color="primaryDark35" size="xl" type="heading">
                {t(`common.no_data`)}
              </Text>
            )}
          </>
        ) : (
          <Placeholder data-testid="placeholder" $height="122px" $width="122px">
            <Skeleton height="122px" width="122px" />
          </Placeholder>
        )}
        <CenteredText color="text30" size="s">
          {t('satisfaction.coachee_experience.taskscore')}
        </CenteredText>
        {!loading ? (
          <>
            {benchmark ? (
              <BrandTag>
                {t('overview.satisfaction.avg_session.benchmark', {
                  number: new Intl.NumberFormat(locale, {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  }).format(benchmark),
                })}
              </BrandTag>
            ) : (
              <BrandTag> {t(`common.no_data`)}</BrandTag>
            )}
          </>
        ) : (
          <BrandTag data-testid="placeholder">
            <Skeleton height="16px" width="70px" />
          </BrandTag>
        )}
      </CenteredColumn>
    </CircularProgressCard>
  );
}
