import React from 'react';

import LookerDashboardLayout from '../components/LookerDashboardLayout';
import AverageSessionsSection from './components/AverageSessionsSection';
import CoachingJourney from './components/CoachingJourney';
import LearningAcademySection from './components/LearningAcademySection';
import LearningBottomSection from './components/LearningBottomSection';

export function LookerEngagementDashboard() {
  return (
    <LookerDashboardLayout>
      <AverageSessionsSection />
      <CoachingJourney />
      <LearningAcademySection />
      <LearningBottomSection />
    </LookerDashboardLayout>
  );
}
