import React from 'react';

import { paddingTop } from '@coachhubio/nova-spaces';
import { Text } from '@coachhubio/nova-text';
import { useTranslation } from 'react-i18next';
import { Row } from 'screens/Dashboard/components/Common';
import styled from 'styled-components';

const Header = styled(Row)`
  ${paddingTop('xl')}
  justify-content: space-between;
`;

const HeaderList = () => {
  const { t } = useTranslation('Dashboard');

  return (
    <Header>
      <Text size="s" color="neutral45" modifier="bold">
        {t('impact.header.focus.areas.title')}
      </Text>
      <Text size="s" color="neutral45" modifier="bold">
        {t('impact.header.goal.reached')}
      </Text>
    </Header>
  );
};

export default HeaderList;
