import React, { FC } from 'react';

import { Skeleton } from '@coachhubio/nova-skeleton';
import { gap } from '@coachhubio/nova-spaces';
import { Heading, Text } from '@coachhubio/nova-text';
import { TooltipProps } from '@coachhubio/nova-tooltip';
import { Link } from 'components/Link';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import InfoTooltip from 'screens/Dashboard/components/InfoTooltip';
import styled from 'styled-components';

import { Row, Column, Placeholder, InlinePlaceholder } from '../../components/Common';

const StatusCol = styled(Column)`
  ${gap('s')}
`;

const CenterRow = styled(Row)`
  align-items: center;
  justify-content: center;
  ${gap('xs')}
  flex: 0 0 auto;
`;

const RowWithIcon = styled(CenterRow)`
  & svg {
    width: 44px;
    height: 44px;
  }
`;

type Props = {
  i18title: string;
  count: number;
  isLoading: boolean;
  i18tooltip?: string;
  href: string;
  tooltipProps?: Omit<TooltipProps, 'children'>;
  informationCount: number | undefined;
  i18information: string;
};

function renderHeader(t: TFunction, count: number | undefined, href: string) {
  if (typeof count === 'undefined') {
    return (
      <Heading color="primaryDark35" size="s">
        {t(`common.no_data`)}
      </Heading>
    );
  }
  return (
    <Link href={href}>
      <Heading color="primaryDark35" size="xl">
        {count}
      </Heading>
    </Link>
  );
}

function renderInformation(t: TFunction, informationCount: number | undefined, i18information: string) {
  if (typeof informationCount !== 'undefined') {
    return (
      <CenterRow>
        <Text size="xs" color="neutral60" modifier="regular">
          {t(i18information, { number: informationCount })}
        </Text>
      </CenterRow>
    );
  }

  // No data retrived
  return (
    <Heading color="primaryDark35" size="xs">
      {t(`common.no_data`)}
    </Heading>
  );
}

const StatusCard: FC<Props> = ({
  i18title = '',
  count,
  informationCount,
  i18information,
  isLoading,
  i18tooltip = '',
  tooltipProps = null,
  href,
}) => {
  const { t } = useTranslation('Dashboard');

  return (
    <StatusCol>
      <CenterRow>
        <Text size="xs" color="primaryDark35" modifier="bold">
          {t(i18title) || 'i18title'}
        </Text>
        {!!i18tooltip && <InfoTooltip i18key={i18tooltip} {...tooltipProps} />}
      </CenterRow>
      <RowWithIcon>
        {isLoading ? (
          <Placeholder $height="44px" $width="100px">
            <Skeleton height="44px" width="100px" />
          </Placeholder>
        ) : (
          renderHeader(t, count, href)
        )}
      </RowWithIcon>
      {isLoading ? (
        <CenterRow>
          <InlinePlaceholder $height="16px" $width="100px">
            <Skeleton height="16px" width="100px" />
          </InlinePlaceholder>
        </CenterRow>
      ) : (
        renderInformation(t, informationCount, i18information)
      )}
    </StatusCol>
  );
};

export default StatusCard;
