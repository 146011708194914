import React, { useMemo } from 'react';

import LineChart from 'components/Charts/LineChart';
import { useTranslation } from 'react-i18next';

import { AverageSessionKeys } from './EngagementAverageSessionChat';
import type { AverageSessionData } from './EngagementAverageSessionChat';

const dimensions = {
  height: 200,
  width: 500,
  margin: {
    top: 50,
    bottom: 75,
    left: -5,
    right: 50,
  },
};

type EngageAverageSessionEvolutionProps = {
  data: AverageSessionData[];
};

const colors = ['#2D2F85', '#FF8155', '#97CFFF'];

type Field =
  | typeof AverageSessionKeys.AvgSessionBenchmark
  | typeof AverageSessionKeys.AvgSessionCompleted
  | typeof AverageSessionKeys.TotSessions;

const EngagementAverageSessionEvolution = ({ data }: EngageAverageSessionEvolutionProps) => {
  const { t } = useTranslation('Dashboard');

  const tooltipText = useMemo(
    () => (
      points: {
        field: Field;
        value: {
          x: string;
          y: number;
        };
      }[]
    ) => {
      function getFieldLabel(field: Field) {
        switch (field) {
          case AverageSessionKeys.AvgSessionCompleted:
            return t('engagement.avg_session.legend.average');
          case AverageSessionKeys.AvgSessionBenchmark:
            return t('engagement.avg_session.legend.benchmark');
          case AverageSessionKeys.TotSessions:
            return t('engagement.avg_session.legend.total');
        }
      }
      if (points.length) {
        return [`${t('engagement.avg_session.legend.session_timeline')}: <b> ${points[0].value.x}</b>`]
          .concat(
            points.map(
              ({ field, value }) =>
                `${getFieldLabel(field)}: <b>${
                  field === 'total_sessions' ? value.y : value.y?.toFixed(2) || t(`common.no_data`)
                }</b>`
            )
          )
          .join('<br/>');
      }
      return '';
    },
    [t]
  );
  return (
    <LineChart
      data={data}
      keys={[
        {
          field: AverageSessionKeys.AvgSessionCompleted,
          scale: '1',
          type: 'natural',
          legend: t('engagement.avg_session.legend.average'),
        },
        {
          field: AverageSessionKeys.AvgSessionBenchmark,
          scale: '1',
          type: 'natural',
          legend: t('engagement.avg_session.legend.benchmark'),
        },
        {
          field: AverageSessionKeys.TotSessions,
          scale: '2',
          type: 'linear',
          area: true,
          legend: t('engagement.avg_session.legend.total'),
        },
      ]}
      rangeXKey="coachee_sessions.dynamic_timeframe_order"
      dimensions={dimensions}
      tooltipText={tooltipText}
      primaryScale={{ hidden: false, startsFromZero: true, showGrid: false }}
      secondaryScale={{ hidden: false, startsFromZero: false, showGrid: true }}
      colors={colors}
      pointThreshold={-1}
    />
  );
};

export default EngagementAverageSessionEvolution;
