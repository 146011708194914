import React from 'react';

import { SectionHeader } from 'screens/Dashboard/components/SectionHeader';

import { ColumnWithoutFlex } from '../../components/Common';
import EngagementBanner from '../../Overview/components/EngagementBanner';

import { CoachingJourneyGraphs } from './';

export function CoachingJourney() {
  return (
    <ColumnWithoutFlex>
      <SectionHeader i18title="engagement.journey.title" i18description="engagement.journey.description" />
      <EngagementBanner />
      <CoachingJourneyGraphs />
    </ColumnWithoutFlex>
  );
}

export default CoachingJourney;
