import { CommentFullIcon, FaceHappyIcon, LightBulbIcon, TrendUpIcon } from '@coachhubio/nova-icon';

// Hardcoded dashboard IDs & pixel height
const sectionIds = [95, 91, 96, 93];
const sectionHeights = [2400, 3200, 3600, 1700];
export const sectionNames = [
  'overview',
  'engagement',
  'impact',
  'satisfaction',
  'multirater-feedback',
  'insightsplus',
  'codevhubs',
];
const sectionHrefs = ['/', '/engagement', '/impact', '/satisfaction'];
const sectionIcons = [LightBulbIcon, CommentFullIcon, TrendUpIcon, FaceHappyIcon];

const SectionsProps = { sectionIds, sectionHeights, sectionNames, sectionHrefs, sectionIcons };

export default SectionsProps;
