import React, { useMemo } from 'react';

import { gap, paddingTop } from '@coachhubio/nova-spaces';
import useGetLookerData from 'hooks/useGetLookerData';
import { LookerFieldsTag } from 'screens/Dashboard/utils/lookerFields';
import styled from 'styled-components';

import { SessionScoreBar, SessionScoreBarOverview } from './';

const Container = styled.div`
  ${paddingTop('m')}
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${gap('s')};
`;

const OverviewContainer = styled.div`
  ${paddingTop('l')}
`;

const SessionKeys = {
  AvgBenchmark: 'average_of_benchmark_metric_value',
  AvgSessionCompleted: 'avg_session.avg_sessions_completed',
  Benchmark: 'benchmark',
  Diff: 'diff_between_session_and_benchmark',
  Sessions: 'sessions',
} as const;

export type AverageSessionsBySelectedPeriod = {
  [SessionKeys.AvgBenchmark]: number;
  [SessionKeys.AvgSessionCompleted]: number;
  [SessionKeys.Benchmark]: number;
  [SessionKeys.Diff]: number;
  [SessionKeys.Sessions]: number;
};

const LastMonthKeys = {
  AvgBenchmark: 'average_of_benchmark_metric_value',
  AvgSessionCompleted: 'avg_session.avg_sessions_completed',
  Benchmark: 'benchmark',
  TimeFrame: 'coachee_sessions.dynamic_timeframe_order',
  Diff: 'diff_between_benchmark_and_avg',
  MonthFlag: 'last_month_avg',
  Sessions: 'sessions',
} as const;

export type AverageSessionsFromLastMonths = {
  [LastMonthKeys.AvgBenchmark]: number;
  [LastMonthKeys.AvgSessionCompleted]: number;
  [LastMonthKeys.Benchmark]: number;
  [LastMonthKeys.TimeFrame]: string;
  [LastMonthKeys.Diff]: number;
  [LastMonthKeys.MonthFlag]: 'Yes' | 'No';
  [LastMonthKeys.Sessions]: number;
};

const AverageSessionsScoreBars = () => {
  const { loading: isSelectedPeriodLoading, data: averageSessionsBySelectedPeriodArray = [] } = useGetLookerData<
    AverageSessionsBySelectedPeriod[]
  >(LookerFieldsTag.AverageSessionsBySelectedPeriod);
  const { loading: isLastMonthLoading, data: averageSessionsFromLastMonth = [] } = useGetLookerData<
    AverageSessionsFromLastMonths[]
  >(LookerFieldsTag.AverageSessionsFromLastMonth);

  const averageSessionsBySelectedPeriod = averageSessionsBySelectedPeriodArray[0];

  const lastMonthSession = averageSessionsFromLastMonth.find((item) => item.last_month_avg === 'Yes');

  const avgSessionsByPeriodIsPositive = averageSessionsBySelectedPeriod?.diff_between_session_and_benchmark > 0;
  const avgSessionsFromLastMonthIsPositive = (lastMonthSession?.diff_between_benchmark_and_avg ?? 0) > 0;

  const i18overviewDescription = useMemo(() => {
    if (avgSessionsByPeriodIsPositive && avgSessionsFromLastMonthIsPositive) {
      return 'engagement.avg_session.insight.positive';
    }
    if (avgSessionsByPeriodIsPositive && !avgSessionsFromLastMonthIsPositive) {
      return 'engagement.avg_session.insight.declining';
    }
    if (!avgSessionsByPeriodIsPositive && avgSessionsFromLastMonthIsPositive) {
      return 'engagement.avg_session.insight.improving';
    }
    if (!avgSessionsByPeriodIsPositive && !avgSessionsFromLastMonthIsPositive) {
      return 'engagement.avg_session.insight.negative';
    }
  }, [avgSessionsByPeriodIsPositive, avgSessionsFromLastMonthIsPositive]);

  return (
    <Container>
      <Grid>
        <SessionScoreBar
          average={averageSessionsBySelectedPeriod?.[SessionKeys.Diff]}
          benchmark={averageSessionsBySelectedPeriod?.[SessionKeys.Benchmark]}
          count={averageSessionsBySelectedPeriod?.[SessionKeys.AvgSessionCompleted]}
          i18description="engagement.avg_session.label.selected"
          isLoading={isSelectedPeriodLoading}
        />
        <SessionScoreBar
          average={lastMonthSession?.[LastMonthKeys.Diff]}
          benchmark={lastMonthSession?.[LastMonthKeys.Benchmark]}
          count={lastMonthSession?.[LastMonthKeys.AvgSessionCompleted] ?? 0}
          i18description="engagement.avg_session.label.previous_month"
          isLoading={isLastMonthLoading}
        />
      </Grid>
      <OverviewContainer>
        <SessionScoreBarOverview
          isLoading={isSelectedPeriodLoading && isLastMonthLoading}
          i18overviewDescription={i18overviewDescription}
        />
      </OverviewContainer>
    </Container>
  );
};

export default AverageSessionsScoreBars;
