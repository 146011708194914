import React from 'react';

import { getBackgroundColor, getBorderColor } from '@coachhubio/nova-colors';
import { Skeleton } from '@coachhubio/nova-skeleton';
import { gap, paddingY } from '@coachhubio/nova-spaces';
import { Text } from '@coachhubio/nova-text';
import { useTranslation } from 'react-i18next';
import { CenteredColumn, Column, InlinePlaceholder } from 'screens/Dashboard/components/Common';
import styled from 'styled-components';

import type { LearningAcademyTopAssignment } from './TopAssignmentsList';

const Container = styled.div`
  display: grid;
  border-top: 1px solid;

  ${gap('s')}
  ${paddingY('m')}
  ${getBorderColor('neutral80')};

  &:first-child {
    border-top: none;
  }
`;

const Card = styled.div`
  grid-template-columns: 50px 1fr;
  display: grid;
`;

const TextContainer = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  ${getBackgroundColor('brandDark')}
`;

type Props = {
  isLoading: boolean;
  assignment: LearningAcademyTopAssignment;
};

function AssignmentCard({ isLoading, assignment }: Props) {
  const { t } = useTranslation('Dashboard');

  return (
    <Container>
      {isLoading ? (
        <InlinePlaceholder $height="44px" $width="100%">
          <Skeleton height="44px" width="100%" />
        </InlinePlaceholder>
      ) : assignment ? (
        <Card>
          <CenteredColumn>
            <TextContainer>
              <Text modifier="bold" size="s" color="neutral">
                {assignment?.row}
              </Text>
            </TextContainer>
          </CenteredColumn>
          <Column>
            <Text color="primaryDark35" modifier="bold">
              {assignment?.['nuggets_assigned.title']}
            </Text>
            <Text color="text30" size="s">
              {t('engagement.academy.completion_rate.description', {
                count: assignment?.coachees_assigned_to,
                count2: assignment?.total_assignment,
              })}
            </Text>
          </Column>
        </Card>
      ) : (
        <Text color="primaryDark35" size="m">
          {t('common.no_data')}
        </Text>
      )}
    </Container>
  );
}

export default AssignmentCard;
