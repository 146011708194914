import React from 'react';

import { InfoIcon } from '@coachhubio/nova-icon';
import { gap } from '@coachhubio/nova-spaces';
import { Tag } from '@coachhubio/nova-tag';
import useGetLookerData from 'hooks/useGetLookerData';
import { useTranslation } from 'react-i18next';
import AverageDaysChart from 'screens/Dashboard/components/AverageDaysChart';
import CircularProgressionChart from 'screens/Dashboard/components/CircularProgressionChart';
import { LookerFieldsTag } from 'screens/Dashboard/utils/lookerFields';
import styled from 'styled-components';

import { SubTitle, Column } from '../../components/Common';

export interface ActiveCoacheesWithSession {
  'benchmark_fixed_period.benchmark_value': number;
  active_coachees_with_more_than_1_session_completed: number;
  'coachee.m_coachees': number;
  coachees_who_completed_more_than_1_sessions: number;
}

export interface AdoptionDaysToSession {
  average_number_of_days_before_the_first_session: number;
  avg_days_between_login_and_first_session_date: number;
  'benchmark_fixed_period.benchmark_value': number;
}

const SCol = styled(Column)`
  width: 100%;

  ${gap('2xl')}
`;

const CoacheesAdoptionCard = () => {
  const { t } = useTranslation('Dashboard');

  const { data: activeCoacheesWithSession = [], loading: activeCoacheesWithSessionLoading } = useGetLookerData<
    ActiveCoacheesWithSession[]
  >(LookerFieldsTag.EngagementAdoptionActive);
  const { data: adoptionDaysToSession = [], loading: adoptionDaysToSessionLoading } = useGetLookerData<
    AdoptionDaysToSession[]
  >(LookerFieldsTag.EngagementAdoptionFirstLogin);

  return (
    <SCol>
      <Column>
        <SubTitle>{t('engagement.journey.adoption.title')}</SubTitle>
        <Tag iconPosition="start" variant="hint" Icon={InfoIcon}>
          {t('overview.engagement.avg_sessions.info')}
        </Tag>
      </Column>
      <SCol>
        <CircularProgressionChart
          benchmarkData={activeCoacheesWithSession[0]?.['benchmark_fixed_period.benchmark_value']}
          i18description="engagement.journey.adoption.pie_description"
          isLoading={activeCoacheesWithSessionLoading}
          displayedValue={activeCoacheesWithSession[0]?.active_coachees_with_more_than_1_session_completed}
        />
        <AverageDaysChart
          benchmarkData={adoptionDaysToSession[0]?.['benchmark_fixed_period.benchmark_value']}
          i18description="engagement.journey.adoption.days.description"
          isLoading={adoptionDaysToSessionLoading}
          displayedValue={adoptionDaysToSession[0]?.average_number_of_days_before_the_first_session}
        />
      </SCol>
    </SCol>
  );
};

export default CoacheesAdoptionCard;
