import { useCallback } from 'react';

import { ChevronDownIcon, ChevronUpIcon, ChevronsVerticalIcon } from '@coachhubio/nova-icon';

export type SortDirections = 'asc' | 'desc' | 'none';

const nextSortDirectionMap: { [key in SortDirections]: SortDirections } = {
  none: 'asc',
  asc: 'desc',
  desc: 'none',
};

export function useTableSortingIcon(orderBy: string, orderDirection: SortDirections) {
  const getSortIcon = useCallback(
    (key: string) =>
      orderBy === key && orderDirection !== 'none'
        ? orderDirection === 'asc'
          ? ChevronDownIcon
          : ChevronUpIcon
        : ChevronsVerticalIcon,
    [orderBy, orderDirection]
  );

  return { getSortIcon, nextSortDirectionMap };
}
