import { useEffect, useMemo, useState } from 'react';

import { useLooker } from 'context/LookerContext';
import { usePrevious } from 'hooks/usePrevious';
import { FiltersData, FiltersOptions, getLookerFilters } from 'utils/fetchDashboard';

export type SingleSelectFilter = keyof Pick<
  FiltersData,
  'benchmark_types' | 'career_levels' | 'genders' | 'data_range'
>;
export type MultiSelectFilter = keyof Pick<FiltersData, 'organizations' | 'programs' | 'countries'>;
export type FilterName = SingleSelectFilter | MultiSelectFilter;

type FilterValues = Record<SingleSelectFilter, string> & Record<MultiSelectFilter, string[]>;

export const defaultFilterValues: FilterValues = {
  benchmark_types: 'All Companies',
  career_levels: 'all',
  genders: 'all',
  organizations: [],
  programs: [],
  countries: [],
  data_range: '6',
};

type Fn<T> = (value: T) => void;

const DEFAULT_DATA_GRANULARITY = 'Monthly';

/**
 * Detected if an object is a literal object
 */
function isLiteralObject(obj: any) {
  return !!obj && obj.constructor === Object;
}

function useLookerFilters() {
  const { looker, updateFiltersAPI } = useLooker();

  // List of filters to display
  const [filtersOptions, setFiltersOptions] = useState<FiltersOptions | undefined>();

  const [filters, setFilters] = useState<FilterValues>(defaultFilterValues);
  const updateFilter = useMemo(
    () => (name: FilterName) => (value: string | string[]) =>
      setFilters((filters) => ({
        ...filters,
        [name]: value,
      })),
    [setFilters]
  ) as (name: FilterName) => typeof name extends SingleSelectFilter ? Fn<string> : Fn<string[]>;

  const prevFilters = usePrevious(filters);

  useEffect(() => {
    const fetchFilters = async function (): Promise<void> {
      const options = await getLookerFilters(looker!);
      if (options) {
        setFiltersOptions(options);
      }
    };

    setFiltersOptions(undefined);
    if (looker) {
      fetchFilters();
    }
  }, [looker]);

  useEffect(() => {
    const storage = window.localStorage.getItem('dashboardFilters');
    const storedFilters = JSON.parse(storage ?? '{}') as Partial<FilterValues>;

    // Update filters only if the one retrieved from localStorage is a literal object
    if (isLiteralObject(storedFilters)) {
      setFilters({
        ...defaultFilterValues,
        ...storedFilters,
      });
    }

    if (looker) {
      updateFiltersAPI({
        benchmark_types: storedFilters?.benchmark_types ?? defaultFilterValues['benchmark_types'],
        career_levels: storedFilters?.career_levels ?? defaultFilterValues['career_levels'],
        genders: storedFilters?.genders ?? defaultFilterValues['genders'],
        data_granularity: DEFAULT_DATA_GRANULARITY,
        data_range: storedFilters?.data_range ?? defaultFilterValues['data_range'],
        organizations: (storedFilters?.organizations ?? defaultFilterValues['organizations']).join(','),
        programs: (storedFilters?.programs ?? defaultFilterValues['programs']).join(','),
        countries: (storedFilters?.countries ?? defaultFilterValues['countries']).join(','),
      });
    }
  }, [looker, updateFiltersAPI]);

  const handleFilterClick = () => {
    updateFiltersAPI({
      benchmark_types: filters.benchmark_types ?? defaultFilterValues.benchmark_types,
      career_levels: filters.career_levels ?? defaultFilterValues.career_levels,
      genders: filters.genders ?? defaultFilterValues.genders,
      organizations: (filters.organizations ?? defaultFilterValues.organizations).join(','),
      programs: (filters.programs ?? defaultFilterValues.programs).join(','),
      countries: (filters.countries ?? defaultFilterValues.countries).join(','),
      data_granularity: DEFAULT_DATA_GRANULARITY,
      data_range: filters.data_range ?? defaultFilterValues.data_range,
    });

    window.localStorage.setItem('dashboardFilters', JSON.stringify(filters));
  };

  return {
    prevFilters,
    currentFilters: filters,
    updateFilter,
    handleFilterClick,
    filtersOptions,
  };
}

export default useLookerFilters;
