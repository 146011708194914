import React from 'react';

import Bond from './Bond';
import Goal from './Goal';
import Header from './Header';
import Rating from './Rating';
import { RowWithGap } from './Styles';
import Task from './Task';

const CircleMaxValue = 5;

const CoacheeExperience = () => {
  return (
    <>
      <Header data-testid="coachee-experience-header" />
      <RowWithGap>
        <Rating data-testid="coachee-experience-rating" />
        <Bond data-testid="coachee-experience-bond" max={CircleMaxValue} />
        <Task data-testid="coachee-experience-task" max={CircleMaxValue} />
        <Goal data-testid="coachee-experience-goal" max={CircleMaxValue} />
      </RowWithGap>
    </>
  );
};

export default CoacheeExperience;
