import { LinearProgress } from '@coachhubio/ui-components';
import { Title } from 'components/Title';
import styled from 'styled-components';

import { LookerDashboardError } from '../components/LookerDashboardError';
import { Iframe } from '../Iframe';
import { useStyles } from '../LookerDashboard';
import { MRFState, useMRF } from './useMRF';

const IFramePadding = styled('div')`
  padding-left: 1rem;
  padding-right: 1rem;
  @media screen and (min-width: 1060px) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  @media screen and (min-width: 1490px) {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
`;

const IFrameTitle = styled(Title)`
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: space-between;
  height: 65px;
  @media screen and (min-width: 1060px) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  @media screen and (min-width: 1490px) {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
`;

const Wrapper = styled('div')`
  min-height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  flex-grow: 1;
  padding: 20px;
`;

const ContainerScaled = styled('div')`
  margin-bottom: 0;
  height: 100%;
`;

export function LookerMRFDashboard() {
  const classes = useStyles({});
  const state = useMRF();

  function renderIFrame(looker: MRFState['looker']): JSX.Element {
    if (looker.loading) {
      return (
        <IFramePadding>
          <LinearProgress />
        </IFramePadding>
      );
    }
    if (looker.error) {
      return <LookerDashboardError />;
    }
    return <Iframe url={looker.url!} />;
  }

  return (
    <Wrapper>
      <IFrameTitle data-testid="head-section">
        {state.org.loading ? null : (
          <>
            <span data-hj-suppress>{state.org.data?.name ?? 'No Name'}</span>
            {state.org.data?.logo ? (
              <img
                src={state.org.data.logo}
                alt={`${state.org.data.name} logo`}
                className={classes.logo}
                data-hj-suppress
              />
            ) : null}
          </>
        )}
      </IFrameTitle>
      <ContainerScaled>{renderIFrame(state.looker)}</ContainerScaled>
    </Wrapper>
  );
}
