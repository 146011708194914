import React from 'react';

import { Alert, makeStyles } from '@coachhubio/ui-components';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}));

export function LookerDashboardError() {
  const classes = useStyles();
  const { t } = useTranslation('Error');

  return (
    <div className={classes.root}>
      <Alert severity="error">{t('defaultTitle')}</Alert>
    </div>
  );
}
