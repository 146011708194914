export enum LookerFieldsTag {
  AreasForImprovement = 'impact_strength_assessment_devlopement_areas',
  AssignmentCompletionRate = 'engagement_learning_academy_completion_rate',
  AssignmentsByFocusArea = 'engagement_learning_academy_assignments_by_focusarea',
  AverageSessionsBySelectedPeriod = 'engagement_avg_session_selected_period',
  AverageSessionMonthly = 'engagement_avg_session_monthly',
  AverageSessionsFromLastMonth = 'engagement_avg_session_last_month',
  CoacheeActivationJourney = 'overview_engagement_coachee_activation_journey',
  CoacheesRatedCount = 'overview_impact_coachees_rated',
  CoacheesSelected = 'overview_selected_coachees',
  CoacheesUpdateRatingCount = 'impact_focus_area_progress_coachees_selected_rated_FA',
  EngagementAdoptionActive = 'engagement_adoption_active_coachees_with_session',
  EngagementAdoptionFirstLogin = 'engagement_adoption_days_login_to_first_session',
  EngagementJourneyCoacheeStatus = 'engagement_coaching_journey_coachees_by_status',
  EngagementJourneyCoacheeCancelled = 'engagement_coaching_journey_cancelled_coachees',
  FocusAreaProgress = 'impact_focus_area_progress_by_FA',
  FocusAreaProgressCategories = 'impact_focus_area_progress_by_FA_category',
  OverviewLicensesRemaining = 'overview_licenses_remaining_licenses',
  OverviewStuckCoachees = 'overview_engagement_stuck_coachees',
  SatisfactionBondScore = 'satisfaction_bonding_score',
  SatisfactionFeedback = 'satisfaction_feedback_last_6_feedback',
  SatisfactionFeedbackCount = 'satisfaction_feedback_count',
  SatisfactionGoalScore = 'satisfaction_goal_score',
  SatisfactionSessionOverallRating = 'satisfaction_session_overall_ratings',
  SatisfactionSessionRated = 'overview_satisfaction_sessions_rated',
  SatisfactionSessionRating = 'overview_satisfaction_session_rating',
  SatisfactionTaskScore = 'satisfaction_task_score',
  StrengthAssessmentCoachees = 'impact_strength_assessment_coachees',
  TopAssignments = 'engagement_learning_academy_top_assignments',
  TopCategories = 'impact_focus_area_progress_goal_achievement_by_category',
  TopFocusAreas = 'impact_focus_area_progress_goal_achievement_FA',
  TopStrengths = 'impact_strength_assessment_strengths',
  OverviewEngagementAvgSession = 'overview_engagement_avg_session',
  OverviewLicensesDetails = 'overview_licenses_details',
  OverviewCoachingMonthsForLicenses = 'overview_coaching_months_for_licenses',
  OverviewCoachingMonthsAvailability = 'overview_coaching_months_availability',
  OverviewCoachingMonthsConsumption = 'overview_coaching_months_consumption',
}

export type LookerResponseTypes = {
  [LookerFieldsTag.SatisfactionBondScore]: {
    average_of_benchmark_metric_value: number;
    'coachee_sessions.m_avg_bonding': number;
  };
  [LookerFieldsTag.SatisfactionFeedback]: {
    'coachee_sessions.feedback': string;
    'coachee_sessions.m_avg_session_ratings': number;
    'coachee_sessions.startsat_date': string;
    new_calculation_1: number;
  };
  [LookerFieldsTag.SatisfactionFeedbackCount]: {
    count_of_feedback: number;
  };
  [LookerFieldsTag.SatisfactionGoalScore]: {
    'benchmark_fixed_period.benchmark_value': number;
    'coachee_sessions.m_avg_goalsscore': number;
  };
  [LookerFieldsTag.SatisfactionSessionOverallRating]: {
    'coachee_sessions.m_avg_session_ratings': number | null;
    average_of_benchmark_metric_value: number | null;
    'coachee_sessions.m_coachees_with_rating': number | null;
    'coachee_sessions.m_sessions_with_rating': number | null;
  };
  [LookerFieldsTag.SatisfactionSessionRated]: {
    'coachee_sessions.m_sessions_with_rating': string;
  };
  [LookerFieldsTag.SatisfactionSessionRating]: {
    'coachee_sessions.m_avg_session_ratings': number;
    'benchmark_fixed_period.benchmark_value': number;
  };
  [LookerFieldsTag.SatisfactionTaskScore]: {
    'benchmark_fixed_period.benchmark_value': number;
    'coachee_sessions.m_avg_taskscore': number;
  };
};

export type LookerResponse<T extends keyof LookerResponseTypes> = LookerResponseTypes[T];
