import React from 'react';

import { Skeleton } from '@coachhubio/nova-skeleton';
import { paddingY } from '@coachhubio/nova-spaces';
import { gap } from '@coachhubio/nova-spaces';
import { Text } from '@coachhubio/nova-text';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Row, Column, InlinePlaceholder, BrandTag, CenteredColumn } from './Common';

type Props = {
  i18description: string;
  isLoading: boolean;
  benchmarkData: string | number;
  displayedValue: number;
};

const TRow = styled(Row)`
  ${gap('s')}
  display: grid;
  grid-template-columns: 140px 1fr;
  justify-content: center;
  align-items: center;
`;

const InfoColumn = styled(Column)`
  width: 100%;
  ${gap('xs')}
  ${paddingY('xs')}
`;

const GraphCol = styled(Column)`
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const AverageDaysChart = ({ isLoading = true, i18description, benchmarkData, displayedValue }: Props) => {
  const { t } = useTranslation('Dashboard');
  return (
    <TRow>
      <GraphCol>
        {isLoading ? (
          <InlinePlaceholder $height="86px" $width="86px">
            <Skeleton height="86px" width="86px" />
          </InlinePlaceholder>
        ) : displayedValue ? (
          <CenteredColumn>
            <Text color="brandDark" size="xl" modifier="bold">
              {displayedValue}
            </Text>
            <Text color="brandDark" size="xs" modifier="bold">
              {t('engagement.journey.adoption.days.label')}
            </Text>
          </CenteredColumn>
        ) : (
          <Text>{t('common.no_data')}</Text>
        )}
      </GraphCol>
      <InfoColumn>
        <Text color="text30" size="s">
          {t(i18description)}
        </Text>
        {isLoading ? (
          <InlinePlaceholder $height="16px" $width="25px">
            <Skeleton height="16px" width="25px" />
          </InlinePlaceholder>
        ) : benchmarkData ? (
          <BrandTag>
            {t('overview.satisfaction.avg_session.benchmark', { number: benchmarkData })} {t('shortDay')}
          </BrandTag>
        ) : (
          <BrandTag variant="hint">{t('common.no_data')}</BrandTag>
        )}
      </InfoColumn>
    </TRow>
  );
};

export default AverageDaysChart;
