import { LookerFieldsTag } from 'screens/Dashboard/utils/lookerFields';

import useGetLookerData from './useGetLookerData';

export const KEYS = {
  PERC: 'of_availability_months',
  OWN: 'sum_of_licenses_available_directly_from_this_bu',
  OTHER: 'sum_of_licenses_available_from_other_bus',
  TOTAL: 'sum_of_total_licenses_available',
  MONTHS_OWN: 'sum_of_months_available_directly_from_this_bu',
  MONTHS_OTHER: 'sum_of_months_available_from_other_bus',
  MONTHS_PURCHASED: 'sum_of_total_months_purchased',
} as const;

export interface LicensesData {
  [KEYS.PERC]: number;
  [KEYS.OWN]: number;
  [KEYS.OTHER]: number;
  [KEYS.MONTHS_OWN]: number;
  [KEYS.MONTHS_OTHER]: number;
  [KEYS.TOTAL]: number;
}

// I need to use type instead of interface to prevent error
// Argument of type 'OverviewFilters' is not assignable to parameter of type 'Record<string, string | string[]>'.ts(2345)
export type LicensesFilters = {
  owner_bu_org_id: string;
  program_duration: string;
  program_type: string;
  expires_in: string;
};

// I can use useMemo to prevent infinite loop, but this works even better
const globalFilters = {};

const defaultFilters: LicensesFilters = {
  owner_bu_org_id: '',
  program_duration: '',
  program_type: '',
  expires_in: 'non^_expired',
};

export function useCoachingMonthsForLicenses(filters = defaultFilters) {
  const response = useGetLookerData<LicensesData[]>(
    LookerFieldsTag.OverviewCoachingMonthsForLicenses,
    globalFilters, // override global filters
    filters
  );

  return response;
}
