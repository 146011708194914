import { marginBottom, marginTop } from '@coachhubio/nova-spaces';
import { Column, Placeholder } from 'screens/Dashboard/components/Common';
import { DownloadDropdownButton } from 'screens/Dashboard/components/DownloadDropdownButton';
import styled from 'styled-components';

export const HeaderCol = styled(Column)`
  ${marginBottom('xl')}
`;

export const DownloadButton = styled(DownloadDropdownButton)`
  align-self: flex-end;
`;

export const ListCol = styled(Column)`
  ${marginTop('l')}
`;

export const FeedbackPlaceholder = styled(Placeholder)`
  ${marginBottom('base')}
`;
