import React, { createContext } from 'react';

import useInitializeLooker, { ILooker } from 'hooks/useInitializeLooker';
import { useRouter } from 'next/router';

const LookerContext = createContext<ILooker | undefined>(undefined);

type LookerProviderProps = {
  children: React.ReactElement;
};

function LookerProvider({ children }: LookerProviderProps) {
  const router = useRouter();
  const page = router.route.replace(/\//g, '');
  const {
    filters,
    looker,
    organizationData,
    sorts,
    updateSorts,
    updateFiltersAPI,
    score,
    updateScore,
  } = useInitializeLooker({ page });
  const value = { filters, looker, organizationData, sorts, updateSorts, updateFiltersAPI, score, updateScore };
  return <LookerContext.Provider value={value}>{children}</LookerContext.Provider>;
}

function useLooker() {
  const context = React.useContext(LookerContext);
  if (context === undefined) {
    throw new Error('useLooker must be used within a LookerProvider');
  }
  return context;
}

export { LookerProvider, useLooker };
