import React from 'react';
import type { ReactElement } from 'react';

import { useLooker } from 'context/LookerContext';
import styled from 'styled-components';

import { Column } from '../../components/Common';
import { SectionHeader } from '../../components/SectionHeader';
import DistributionOfAssignmentsChartCard from './DistributionOfAssignmentsChartCard';

const SectionColumn = styled(Column)`
  flex-grow: 0;
`;

const LearningAcademySection = (): ReactElement => {
  const { filters } = useLooker();
  return (
    <SectionColumn>
      <SectionHeader i18title="engagement.academy.title" i18description="engagement.academy.description" />
      {filters ? <DistributionOfAssignmentsChartCard filters={filters} /> : null}
    </SectionColumn>
  );
};

export default LearningAcademySection;
