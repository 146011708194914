import React from 'react';

import { Skeleton } from '@coachhubio/nova-skeleton';
import { marginBottom, paddingX } from '@coachhubio/nova-spaces';
import { Heading } from '@coachhubio/nova-text';
import useGetLookerData from 'hooks/useGetLookerData';
import { useTranslation } from 'react-i18next';
import { DownloadDropdownIconButton } from 'screens/Dashboard/components/DownloadDropdownIconButton';
import { LookerFieldsTag } from 'screens/Dashboard/utils/lookerFields';
import styled from 'styled-components';

import { Column, Placeholder, SubTitle, Description, BigCard } from '../../components/Common';
import CoacheeActivationJourneyChart, { JourneyData } from './CoacheeActivationJourneyChart';

const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const CustomTitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const JourneyTitle = styled(SubTitle)`
  display: flex;
  justify-content: center;
  width: 100%;

  ${marginBottom('none')}
`;

const JourneyDescription = styled(Description)`
  text-align: center;
  margin-top: 14px;

  ${paddingX('l')}
`;

enum TableColumns {
  Invited = 'coachee.m_coachee_who_were_in_invited',
  InMatching = 'coachee.m_coachee_who_were_in_matching',
  Matched = 'coachee.m_coachee_who_were_in_matched',
  Active = 'coachee.m_coachee_who_were_in_active',
  Completed = 'coachee.m_coachee_completed',
}

export function GraphActivationJourney() {
  const { t } = useTranslation('Dashboard');

  const { loading, data = [] } = useGetLookerData<JourneyData[]>(LookerFieldsTag.CoacheeActivationJourney);

  const activationJourney = data[0];

  return (
    <BigCard>
      <Column>
        <CustomTitleRow>
          <JourneyTitle>{t('overview.engagement.coachee_activation.title')}</JourneyTitle>
          <DownloadDropdownIconButton
            lookName={LookerFieldsTag.CoacheeActivationJourney}
            columns={{
              [TableColumns.Invited]: t('overview.engagement.coachee_activation.legend.invited'),
              [TableColumns.InMatching]: t('overview.engagement.coachee_activation.legend.matching'),
              [TableColumns.Matched]: t('overview.engagement.coachee_activation.legend.matched'),
              [TableColumns.Active]: t('overview.engagement.coachee_activation.legend.active'),
              [TableColumns.Completed]: t('overview.engagement.coachee_activation.legend.completed'),
            }}
            option="csv"
          />
        </CustomTitleRow>
        <JourneyDescription color="primaryDark25" size="xs">
          {t('overview.engagement.coachee_activation.description')}
        </JourneyDescription>
      </Column>
      {activationJourney ? (
        <ChartWrapper>
          <CoacheeActivationJourneyChart data={activationJourney} />
        </ChartWrapper>
      ) : (
        <>
          {loading ? (
            <Placeholder $height="310px" $width="100%">
              <Skeleton height="310px" width="100%" />
            </Placeholder>
          ) : (
            <Heading color="primaryDark35" size="xl">
              {t(`common.no_data`)}
            </Heading>
          )}
        </>
      )}
    </BigCard>
  );
}
