/** CoacheeActivationJourney.js */
import React, { useEffect, useMemo, useRef } from 'react';

import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';

import { IStrengthData } from '../types';

type TopStrengthChartProps = {
  data: IStrengthData[];
  type: 'strength' | 'improvement';
};

const dimensions = {
  width: 240,
  height: 300,
  margin: {
    top: 40,
    right: 50,
    bottom: 0,
    left: 200,
  },
};

const HorizontalBarChart = ({ data, type }: TopStrengthChartProps) => {
  const svgRef = useRef(null);
  const { t } = useTranslation('Dashboard');

  const { width, height, margin } = dimensions;
  const svgWidth = width + margin.left + margin.right;
  const svgHeight = height + margin.top + margin.bottom;

  const color = useMemo(() => {
    return type === 'strength' ? [49, 51, 145, 0.1] : [252, 87, 57, 0.1];
  }, [type]);

  const maxItems = 8;

  const tData = data.filter((d) => {
    return d['row'] <= maxItems;
  });

  useEffect(() => {
    const svgEl = d3.select(svgRef.current);

    svgEl.selectAll('*').remove(); // Clear svg content before adding new elements

    svgEl.append('rect').attr('width', '100%').attr('height', '100%').attr('fill', 'white');

    const svg = svgEl.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

    const maxVal = Math.max(
      ...tData.map((d) => {
        return d['coachee.m_coachees'];
      })
    );

    var x = d3.scaleLinear().domain([0, maxVal]).range([0, width]);

    // Y axis
    var y = d3
      .scaleBand()
      .range([0, height])
      .domain(
        tData.map(function (d) {
          return t(d['assessments.title'].replace(/::/g, '.'));
        })
      )
      .padding(0.3);
    svg.append('g').call(d3.axisLeft(y));

    svg.selectAll('.tick line').remove();
    svg.selectAll('.domain').remove();

    svg
      .selectAll('.tick text')
      .style('font-size', '15px')
      .style('fill', '#51537B')
      .attr('font-family', 'Montserrat')
      .attr('font-size', '12px')
      .attr('font-weight', '400')
      .attr('line-height', '18px')
      .attr('letter-spacing', '0px')
      .attr('transform', `translate(-${margin.left - 10}, 0)`)
      .attr('text-anchor', 'start');

    //Bars
    svg
      .selectAll('myRect')
      .data(tData)
      .enter()
      .append('rect')
      .attr('transform', 'translate(0,0)')
      .attr('x', x(0))
      .attr('y', function (d) {
        return y(t(d['assessments.title'].replace(/::/g, '.')))!;
      })
      .attr('width', function (d) {
        return x(d['coachee.m_coachees']);
      })
      .attr('height', y.bandwidth())
      .attr('fill', function (d) {
        const opacity = 1 - (d['row'] - 1) * color[3];
        return `rgba(${color[0]},${color[1]},${color[2]}, ${opacity})`;
      });

    svg
      .selectAll('.bar-title')
      .data(tData)
      .enter()
      .append('text')
      .classed('bar-title', true)
      .attr('text-anchor', 'middle')
      .attr('x', (d) => x(d['coachee.m_coachees']) + 20)
      .attr('y', (d) => y(t(d['assessments.title'].replace(/::/g, '.')))! + y.bandwidth() / 1.5)
      .style('fill', 'black')
      .attr('font-family', 'Montserrat')
      .attr('font-size', '12px')
      .attr('font-weight', '400')
      .attr('line-height', '16px')
      .attr('letter-spacing', '0px')
      .attr('text-align', 'left')
      .text((d) => `${d['coachee.m_coachees']}`);
  }, [tData, color, margin.left, margin.top, width, height, t]);

  return <svg ref={svgRef} width={svgWidth} height={svgHeight} />;
};

export default HorizontalBarChart;
