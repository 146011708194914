import { useCallback, useMemo } from 'react';

import { Skeleton } from '@coachhubio/nova-skeleton';
import { Tag } from '@coachhubio/nova-tag';
import useGetLookerData from 'hooks/useGetLookerData';
import { useTranslation } from 'react-i18next';
import { Placeholder } from 'screens/Dashboard/components/Common';
import { SectionHeader } from 'screens/Dashboard/components/SectionHeader';
import { LookerFieldsTag, LookerResponse } from 'screens/Dashboard/utils/lookerFields';

import { HeaderWrapper } from './Styles';

type HeaderProps = {} & { [dataAttribute: `data-${string}`]: string };

const BASE_KEY = 'satisfaction.coachee_experience.feedbackcount';

export type SessionFeedbackData = Pick<
  LookerResponse<LookerFieldsTag.SatisfactionSessionOverallRating>,
  'coachee_sessions.m_sessions_with_rating' | 'coachee_sessions.m_coachees_with_rating'
>;

function isEmpty(value: any): boolean {
  if (typeof value === 'undefined' || value === null) {
    return true;
  }
  if (typeof value === 'string') return Boolean(value.trim());

  if (typeof value === 'number') return false;
  return Boolean(value);
}

export default function Header(props: HeaderProps) {
  const { t } = useTranslation('Dashboard');

  const { loading, data } = useGetLookerData<SessionFeedbackData[]>(LookerFieldsTag.SatisfactionSessionOverallRating);

  const [sessions, coachees] = useMemo(() => {
    if (Array.isArray(data) && data.length > 0) {
      const {
        'coachee_sessions.m_sessions_with_rating': sessions,
        'coachee_sessions.m_coachees_with_rating': coachees,
      } = data[0];
      return [isEmpty(sessions) ? null : Number(sessions), isEmpty(coachees) ? null : Number(coachees)];
    }
    return [null, null];
  }, [data]);

  const renderCountLabel = useCallback(
    (sessions: number | null, coachees: number | null): string => {
      if (Number.isInteger(sessions)) {
        switch (sessions) {
          case 0:
            return t(`${BASE_KEY}.no_selected`);
          case 1:
            return t(`${BASE_KEY}.one`, { sessions });
          default:
            return t(`${BASE_KEY}.other`, { sessions, count: coachees! });
        }
      }
      return t('common.no_data');
    },
    [t]
  );

  return (
    <HeaderWrapper {...props}>
      <SectionHeader
        i18title={'satisfaction.coachee_experience.title'}
        i18description={'satisfaction.coachee_experience.description'}
      />
      {!loading ? (
        <Tag variant="hint">{renderCountLabel(sessions, coachees)}</Tag>
      ) : (
        <Placeholder data-testid="placeholder" $height="16px" $width="250px">
          <Skeleton height="16px" width="250px" />
        </Placeholder>
      )}
    </HeaderWrapper>
  );
}
