import { useTranslation } from 'react-i18next';
import { LookerFieldsTag } from 'screens/Dashboard/utils/lookerFields';

import { DownloadButton } from './Styles';

type CoacheeFeedbackDownloadProps = { [dataAttribute: `data-${string}`]: string };

export default function CoacheeFeedbackDownload(props: CoacheeFeedbackDownloadProps) {
  const { t } = useTranslation('Dashboard');

  return (
    <DownloadButton
      {...props}
      lookName={LookerFieldsTag.SatisfactionFeedback}
      columns={{
        'coachee_sessions.m_avg_session_ratings': t('satisfaction.coacheesessionfeedback.column.rating'),
        'coachee_sessions.startsat_date': t('satisfaction.coacheesessionfeedback.column.date'),
        'coachee_sessions.feedback': t('satisfaction.coacheesessionfeedback.column.feedback'),
      }}
      option="csv"
    >
      {t('satisfaction.coacheesessionfeedback.download')}
    </DownloadButton>
  );
}
