import React, { useState } from 'react';

import { getColor } from '@coachhubio/nova-colors';
import { Select, Option, SelectValue } from '@coachhubio/nova-select';
import { gap } from '@coachhubio/nova-spaces';
import { useLooker } from 'context/LookerContext';
import { useTranslation } from 'react-i18next';
import { Row } from 'screens/Dashboard/components/Common';
import styled from 'styled-components';

const FilterRow = styled(Row)`
  ${gap('s')}
`;

const StyledSelect = styled(Select)<{ value?: string[] | string }>`
  & ${SelectValue} {
    ${({ value }) => (!value || value.length === 0 ? getColor('neutral60') : '')}
  }
`;

export const defaultFilterValues = {
  score: '',
};

// TODO: This will come in the future from the filters api
const scoreOptions = [
  {
    label: 'common.filters.feedback.all',
    value: '>=0',
  },
  {
    label: 'common.filters.feedback.greater_4',
    value: '>4',
  },
  {
    label: 'common.filters.feedback.between_3_4',
    value: '(3, 4]',
  },
  {
    label: 'common.filters.feedback.between_2_3',
    value: '(2, 3]',
  },
  {
    label: 'common.filters.feedback.less_equal_2',
    value: '<=2',
  },
];

type FeedbackFiltersProps = {} & { [dataAttribute: `data-${string}`]: string };

export default function FeedbackFilters(props: FeedbackFiltersProps) {
  const { t } = useTranslation('Dashboard');

  const { updateSorts, updateScore } = useLooker();
  const SelectPlaceholder = t('common.filters.placeholder');

  const [score, setScore] = useState<string[]>([]);
  const [sort, setSort] = useState<string[]>([]);

  const sortOptions = [
    { value: 'coachee_sessions.m_avg_session_ratings asc', label: t('common.filters.feedback.rating_asc') },
    { value: 'coachee_sessions.m_avg_session_ratings desc', label: t('common.filters.feedback.rating_desc') },
    { value: 'coachee_sessions.startsat_date asc', label: t('common.filters.feedback.date_asc') },
    { value: 'coachee_sessions.startsat_date desc', label: t('common.filters.feedback.date_desc') },
  ];

  return (
    <FilterRow {...props}>
      <StyledSelect
        value={score}
        onChange={(value: string[]) => {
          setScore(value);
          updateScore(value);
        }}
        placeholder={SelectPlaceholder}
        label={t('satisfaction.coacheesessionfeedback.filter.score')}
        size="xs"
        itemsSelectedMessage={t('common.filters.selected')}
        dropdownWidthAuto
      >
        {scoreOptions.map((option, index) => (
          <Option value={option.value} title={t(option.label)} key={`${option.label}-${index}`} />
        ))}
      </StyledSelect>
      <StyledSelect
        value={sort}
        onChange={(value: string[]) => {
          setSort(value);
          updateSorts(value);
        }}
        placeholder={SelectPlaceholder}
        label={t('satisfaction.coacheesessionfeedback.filter.sorting')}
        size="xs"
        itemsSelectedMessage={t('common.filters.selected')}
        dropdownWidthAuto
      >
        {sortOptions.map((option, index) => (
          <Option value={option.value} title={option.label} key={`${option.label}-${index}`} />
        ))}
      </StyledSelect>
    </FilterRow>
  );
}
