import { TFunction } from 'i18next';

import {
  FocusAreaCategoryType,
  ILearningAcademyData,
} from '../screens/Dashboard/Engagement/components/DistributionOfAssignmentsChartCard';

export function downloadtoCSVDistributionOfAssignments(
  lookName: string,
  data: ILearningAcademyData[],
  t: TFunction<'Dashboard'>
) {
  let csv: Array<string> = [];
  for (let i = 0; i < data.length; i++) {
    if (i === 0) {
      let columns: string[] = [];

      // Add the header row
      // Date
      columns.push(t('common.date'));

      for (let key in data[i].percent_of_nuggets_assigned_nuggets_assigned['nuggets_assigned.focus_area_category']) {
        // Translate the key
        switch (key) {
          case 'Balance':
            key = t('common.fa.balance');
            break;
          case 'Communication':
            key = t('common.fa.communication');
            break;
          case 'Empowerment':
            key = t('common.fa.empowerment');
            break;
          case 'Performance':
            key = t('common.fa.performance');
            break;
          case 'Presence':
            key = t('common.fa.presence');
            break;
          case 'Purpose':
            key = t('common.fa.purpose');
            break;
        }
        columns.push(key);
      }

      csv.push(
        columns
          .map(function (item) {
            return '' + item + '';
          })
          .join(', ')
      );
    }

    const focusAreaCategory =
      data[i].percent_of_nuggets_assigned_nuggets_assigned['nuggets_assigned.focus_area_category'];

    let row: any[] = [];
    row.push(data[i]['nuggets_assigned.created_at_month']);

    for (let key in focusAreaCategory) {
      row.push(focusAreaCategory[key as keyof FocusAreaCategoryType]);
    }

    csv.push(
      row
        .map(function (item) {
          return '' + item + '';
        })
        .join(', ')
    );
  }
  csv = csv.join('\n') as any;

  // Download CSV
  const blob = new Blob([String(csv)], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', lookName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
