import React from 'react';

import { makeStyles, Typography } from '@coachhubio/ui-components';
import clsx from 'clsx';

interface TitleProps {
  children: React.ReactNode;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.brandPalette.deepBlue[400],
    height: 80,
    alignItems: 'center',
    display: 'flex',
    letterSpacing: 'inherit',
  },
}));

export function Title({ children, className, ...props }: TitleProps) {
  const classes = useStyles();

  return (
    <Typography variant="h1" className={clsx(classes.root, className)} {...props}>
      {children}
    </Typography>
  );
}
