import React from 'react';

import { Button } from '@coachhubio/nova-button';
import { DownloadIcon } from '@coachhubio/nova-icon';
import { Skeleton } from '@coachhubio/nova-skeleton';
import { Heading } from '@coachhubio/nova-text';
import useGetLookerData from 'hooks/useGetLookerData';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { getReport } from '../../../../utils/fetchDashboard';
import { LookerFieldsTag } from '../../utils/lookerFields';
import type { AverageSessionProps } from '../types';

import { EngagementAverageSessionEvolution } from './';

export const AverageSessionKeys = {
  AvgBenchmark: 'average_of_benchmark_metric_value',
  AvgSessionCompleted: 'avg_session.avg_sessions_completed',
  AvgSessionBenchmark: 'avg_sessions_benchmark',
  Timeframe: 'coachee_sessions.dynamic_timeframe_order',
  SessionCompleted: 'coachee_sessions.m_sessions_completed',
  CurrentMonth: 'current_month',
  TotSessions: 'total_sessions',
} as const;

export type AverageSessionData = {
  [AverageSessionKeys.AvgBenchmark]: number;
  [AverageSessionKeys.AvgSessionCompleted]: number;
  [AverageSessionKeys.AvgSessionBenchmark]: number;
  [AverageSessionKeys.Timeframe]: string;
  [AverageSessionKeys.SessionCompleted]: number;
  [AverageSessionKeys.CurrentMonth]: 'Yes' | 'No';
  [AverageSessionKeys.TotSessions]: number;
};

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const IconContainer = styled.div`
  display: flex;
  width: inherit;
  justify-content: flex-end;
`;

enum TableColumns {
  Date = 'coachee_sessions.dynamic_timeframe_order',
  AverageSession = 'avg_session.avg_sessions',
  AverageBenchmark = 'average_of_benchmark_metric_value',
  TotalSessions = 'total_sessions',
}

const EngagementAverageSessionChat = ({ filtersAPI, lookerConfig }: AverageSessionProps) => {
  const { t } = useTranslation('Dashboard');
  const { loading, data: averageSession = [] } = useGetLookerData<AverageSessionData[]>(
    LookerFieldsTag.AverageSessionMonthly
  );

  return (
    <>
      {averageSession.length > 0 ? (
        <ChartWrapper>
          <IconContainer>
            <Button
              variant="icon"
              squared
              onClick={() =>
                getReport(LookerFieldsTag.AverageSessionMonthly, lookerConfig, filtersAPI, {
                  [TableColumns.Date]: t('common.date'),
                  [TableColumns.AverageSession]: t('engagement.avg_session.legend.average'),
                  [TableColumns.AverageBenchmark]: t('engagement.avg_session.legend.benchmark'),
                  [TableColumns.TotalSessions]: t('engagement.avg_session.legend.total'),
                })
              }
            >
              <DownloadIcon />
            </Button>
          </IconContainer>
          <EngagementAverageSessionEvolution data={averageSession} />
        </ChartWrapper>
      ) : (
        <>
          {loading ? (
            <Skeleton height="100%" width="100%" />
          ) : (
            <Heading color="brandDark" size="l">
              {t(`common.no_data`)}
            </Heading>
          )}
        </>
      )}
    </>
  );
};

export default EngagementAverageSessionChat;
