import { isNil } from 'lodash';

import { addMonthsToDate, toDateObject } from './helper';

const getLocalizedCountryByCode = (locale: string, countryCode: string) => {
  try {
    const countryNames = new Intl.DisplayNames(locale, { type: 'region' });
    return countryNames.of(countryCode);
  } catch (error) {
    console.error(`Failed to get localized countries name for ${locale} and country code ${countryCode} `);
    return undefined;
  }
};

const getLocalizedLanguageByCode = (locale: string, languageCode: string): string | undefined => {
  try {
    const languageName = new Intl.DisplayNames(locale, { type: 'language' });
    return languageName.of(languageCode.replace('_', '-'));
  } catch (error) {
    console.error(`Failed to get localized languages name for ${locale} and code ${languageCode} `);
    return undefined;
  }
};

const localizedNumber = ({
  value,
  locale,
  options,
}: {
  value: number;
  locale: string;
  options?: Intl.NumberFormatOptions;
}) => {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
    ...options,
  }).format(value);
};

const localizedDate = ({
  date,
  locale,
  options,
}: {
  date: string | number | Date;
  locale: string;
  options?: Intl.DateTimeFormatOptions;
}) => {
  if (!isNil(date)) {
    return new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      ...options,
    }).format(toDateObject(date));
  } else {
    return null;
  }
};

const getLocalizedDateRange = ({
  locale,
  duration,
  startDate,
}: {
  locale: string;
  startDate: string | number;
  duration: number;
}) => {
  const localizedStartDate = localizedDate({ date: new Date(startDate).toDateString(), locale });

  const endDate = addMonthsToDate(Number(duration), new Date(startDate).getTime());
  const localizedEndDate = localizedDate({ date: endDate.toDateString(), locale });

  return {
    startDate: localizedStartDate,
    endDate: localizedEndDate,
  };
};

export { localizedDate, getLocalizedCountryByCode, localizedNumber, getLocalizedLanguageByCode, getLocalizedDateRange };
