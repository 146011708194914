import { useState, useEffect } from 'react';

export type status = 'LOADING' | 'VALID' | 'INVALID';

export enum Status {
  LOADING = 'LOADING',
  VALID = 'VALID',
  INVALID = 'INVALID',
}

export function useValidateImageURL(url: string): status {
  const [status, setStatus] = useState<status>('LOADING');

  useEffect(() => {
    setStatus('LOADING');

    let hasChangedURL = false;

    const image = new Image();
    image.src = url || '';

    image.addEventListener('load', () => {
      if (!hasChangedURL) setStatus('VALID');
    });

    image.addEventListener('error', () => {
      if (!hasChangedURL) setStatus('INVALID');
    });
  }, [url]);

  return status;
}
