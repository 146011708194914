import React from 'react';

import LookerDashboardLayout from '../components/LookerDashboardLayout';
import { SectionHeader } from '../components/SectionHeader';
import ProgressGoalsTable from './components/ProgressGoalsTable';
import ProgressTowardsGoalsSection from './components/ProgressTowardsGoalsSection';
import StrengthAssessmentSection from './components/StrengthAssessmentSection';

const LookerImpactDashboard = () => {
  return (
    <LookerDashboardLayout>
      <StrengthAssessmentSection />
      <ProgressGoalsTable />
      <SectionHeader i18title="impact.progress.goals.title" i18description="impact.progress.goals.description" />
      <ProgressTowardsGoalsSection />
    </LookerDashboardLayout>
  );
};

export default LookerImpactDashboard;
