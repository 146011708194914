import React from 'react';

import { Banner } from '@coachhubio/nova-banner';
import { Button } from '@coachhubio/nova-button';
import { getAliasColorValue, getBackgroundColor } from '@coachhubio/nova-colors';
import { Skeleton } from '@coachhubio/nova-skeleton';
import { marginBottom, gap, marginEnd } from '@coachhubio/nova-spaces';
import { Text } from '@coachhubio/nova-text';
import DOMPurify from 'dompurify';
import { useFlags } from 'hooks/useFlags';
import useGetLookerData from 'hooks/useGetLookerData';
import { useRouter } from 'next/router';
import ReactHtmlParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { LookerFieldsTag } from 'screens/Dashboard/utils/lookerFields';
import styled from 'styled-components';

import { Row, Placeholder } from '../../components/Common';

const TitleRow = styled(Row)`
  justify-content: space-between;
  width: 100%;

  ${marginBottom('l')}
`;

const BannerRow = styled(TitleRow)`
  ${marginBottom('none')}
  ${gap('m')}
`;

const BannerMessageRow = styled(Row)`
  align-items: center;

  ${gap('m')}
`;

const ProfileRow = styled(Row)`
  flex-grow: 0;
  height: 26px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 26px;
    width: 26px;
    border-radius: 50%;
  }
`;

const Stack = styled.div`
  position: relative;

  ${marginEnd('s')}
`;

const SmallText = styled(Text)`
  font-size: 10px;
`;

const FakeImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid ${getAliasColorValue('primaryDark95')};
  border-radius: 50%;

  ${getBackgroundColor('primaryDark35')}
`;

const StyledBanner = styled(Banner)`
  ${marginBottom('l')}
`;

type CoachStuck = {
  'coachee.coachee_fullname': string;
  'coachee.coachee_picture': string;
  'coachee.coachee_firstname': string;
};

const EngagementBanner = () => {
  const router = useRouter();
  const { t } = useTranslation('Dashboard');

  const { loading, data: coachStuck } = useGetLookerData<CoachStuck[]>(LookerFieldsTag.OverviewStuckCoachees);
  const { enableCoacheeList } = useFlags();

  // Hiding this component (as it includes names) for customers who want to protect coachee anonymity - (specifically Volkswagen)
  if (!enableCoacheeList) {
    return null;
  }

  const hasStuckCoach = coachStuck !== undefined && coachStuck.length > 0;
  const hasManyStuck = hasStuckCoach && coachStuck.length > 2;

  const count = hasManyStuck ? 'many' : `${coachStuck?.length}`;

  return (
    <StyledBanner type="hint" withIcon={false} withIconAlign={false} direction="column">
      <BannerRow test-id="engagement-banner-row">
        {!loading ? (
          <>
            {hasStuckCoach ? (
              <>
                <BannerMessageRow>
                  <ProfileRow>
                    {[...coachStuck].slice(0, 3).map((coachee, index) => (
                      <Stack key={`${coachee['coachee.coachee_fullname']}-${index}`}>
                        {index === 2 ? (
                          <FakeImg>
                            <SmallText modifier="bold" color="neutral">{`+${coachStuck.length - 2}`}</SmallText>
                          </FakeImg>
                        ) : (
                          <>
                            {coachee['coachee.coachee_picture'] ? (
                              <img
                                src={coachee['coachee.coachee_picture']}
                                alt={`${coachee['coachee.coachee_fullname']}`}
                                key={coachee['coachee.coachee_fullname']}
                              />
                            ) : (
                              <FakeImg />
                            )}
                          </>
                        )}
                      </Stack>
                    ))}
                  </ProfileRow>
                  <Text color="primaryDark35">
                    {hasStuckCoach &&
                      ReactHtmlParser(
                        DOMPurify.sanitize(
                          t(`overview.engagement.stuck_coachees_${count}`, {
                            coachee1: coachStuck[0]?.['coachee.coachee_firstname'] || '',
                            coachee2: coachStuck[1]?.['coachee.coachee_firstname'] || '',
                            number: coachStuck.length - 2,
                          })
                        )
                      )}
                  </Text>
                </BannerMessageRow>
                <Button onClick={() => router.push('/coachee-list?stuck=true')}>
                  {t('overview.engagement.stuck_list')}
                </Button>
              </>
            ) : (
              <Text color="primaryDark35">{t('overview.engagement.stuck_coachees_none')}</Text>
            )}
          </>
        ) : (
          <Placeholder $height="36px" $width="100%">
            <Skeleton height="36px" width="100%" />
          </Placeholder>
        )}
      </BannerRow>
    </StyledBanner>
  );
};

export default EngagementBanner;
