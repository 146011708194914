import React, { forwardRef } from 'react';
import type { ReactElement, SetStateAction } from 'react';

import { Button } from '@coachhubio/nova-button';
import { InfoIcon } from '@coachhubio/nova-icon';
import { paddingBottom, marginTop, paddingTop, gap, SpacingToken } from '@coachhubio/nova-spaces';
import { Text } from '@coachhubio/nova-text';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Row, Column, Title } from './Common';

const TitleRow = styled(Row)<{ $position: 'center' | 'start' | 'end'; $marginTop?: SpacingToken | undefined }>`
  ${paddingTop('2xs')}
  ${paddingBottom('xs')}
  flex-grow: 0;

  ${({ $position }) => $position !== 'center' && paddingBottom('xs')}
  ${({ $marginTop }) => ($marginTop ? marginTop($marginTop) : '')}
`;

const TitleDescription = styled(Text)`
  max-width: 800px;
  text-align: center;
`;

const HeaderColumn = styled(Column)<{ $position: 'center' | 'start' | 'end' }>`
  ${marginTop('xs')}

  ${({ $position }) =>
    $position === 'start'
      ? `
    align-items: flex-start ;
  `
      : $position === 'end'
      ? `
      align-items: flex-end ;
  `
      : `
      align-items: center;
  `}
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  ${gap('xs')}
`;

type Props = {
  i18title: string;
  i18description?: string;
  position?: 'center' | 'start' | 'end';
  withIcon?: boolean;
  showBanner?: boolean;
  setShowBanner?: (value: SetStateAction<boolean>) => void;
  marginTop?: SpacingToken;
};

export const SectionHeader = forwardRef<HTMLDivElement, Props>(
  (
    {
      i18title = 'Add title',
      i18description,
      position = 'center',
      withIcon = false,
      showBanner,
      setShowBanner = () => {},
      marginTop,
    },
    ref
  ): ReactElement => {
    const { t } = useTranslation('Dashboard');

    return (
      <TitleRow $position={position} $marginTop={marginTop} ref={ref}>
        <HeaderColumn $position={position} id="column">
          <TitleContainer>
            <Title>{t(i18title)}</Title>
            {withIcon ? (
              <Button size="xs" variant="icon" squared onClick={() => (!showBanner ? setShowBanner(true) : null)}>
                <InfoIcon size="xs" color="primaryDark35" />
              </Button>
            ) : null}
          </TitleContainer>
          {!!i18description ? (
            <TitleDescription color="primaryDark25" size="s">
              {t(i18description)}
            </TitleDescription>
          ) : null}
        </HeaderColumn>
      </TitleRow>
    );
  }
);
