import React, { useRef, useState } from 'react';

import { InfoIcon } from '@coachhubio/nova-icon';
import { getIconSize } from '@coachhubio/nova-icons';
import { Text } from '@coachhubio/nova-text';
import { Tooltip, TooltipProps } from '@coachhubio/nova-tooltip';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  ${getIconSize('xs')}
`;

interface Props extends Partial<TooltipProps> {
  i18key: string;
  i18fileName?: string;
}

function InfoTooltip({ i18key, i18fileName, ...tooltipProps }: Props) {
  const tooltipParentRef = useRef<HTMLDivElement | null>(null);
  const [active, setActive] = useState(false);
  const { t } = useTranslation(!i18fileName ? 'Dashboard' : i18fileName);
  const handleHover = (val: boolean) => {
    return () => setActive(val);
  };

  return (
    <Container onMouseEnter={handleHover(true)} onMouseLeave={handleHover(false)} ref={tooltipParentRef}>
      <InfoIcon size="xs" color="primaryDark35" />
      <Tooltip
        active={active}
        parentRef={tooltipParentRef.current}
        placement="top"
        autoFlip
        variant="dark"
        withArrow
        offset={[0, 8]}
        {...tooltipProps}
      >
        <Text>{t(i18key)}</Text>
      </Tooltip>
    </Container>
  );
}

export default InfoTooltip;
