import React from 'react';
import { useCallback } from 'react';

import { AxisLeft, AxisBottom } from '@visx/axis';
import { localPoint } from '@visx/event';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { scaleLinear, scaleUtc } from '@visx/scale';
import { LinePath } from '@visx/shape';
import { useTooltip, TooltipWithBounds } from '@visx/tooltip';
import { bisector } from '@visx/vendor/d3-array';
import type { NumberValue } from 'd3-scale';
import { useTranslation } from 'react-i18next';

import type { Category } from './DistributionOfAssignmentsChartCard';
import { ASSIGNMENTS_BY_FOCUS_AREA_PARAMS, FocusAreaCategoryType } from './DistributionOfAssignmentsChartCard';

export type FocusAreaData = {
  date: string;
  values: FocusAreaCategoryType;
};

type FocusAreaGraphProps = {
  data: FocusAreaData[];
};

type Margin = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

type Dimensions = {
  width: number;
  height: number;
  margin: Margin;
};

const dimensions: Dimensions = {
  width: 900,
  height: 300,
  margin: {
    top: 39,
    right: 60,
    bottom: 85,
    left: 60,
  },
};

type TooltipData = {
  selected: string;
  date: string;
  assignement: string;
};

const EngagementLearningAcademyAssignmentsByFocusAreaGraph = ({ data }: FocusAreaGraphProps) => {
  const { t } = useTranslation('Dashboard');
  const { showTooltip, hideTooltip, tooltipData, tooltipLeft, tooltipTop } = useTooltip<TooltipData>();
  const { width, height, margin } = dimensions;
  const svgWidth = width + margin.left + margin.right;
  const svgHeight = height + margin.top + margin.bottom;

  const dates = data.map(getDate);

  const timeScale = scaleUtc({
    domain: [Math.min(...dates), Math.max(...dates)],
    range: [0, width],
  });

  const valueScale = scaleLinear<number>({ domain: [0, 1], range: [height, 0] });

  const handleTooltip = useCallback(
    (event: React.TouchEvent<SVGGElement> | React.MouseEvent<SVGGElement>) => {
      const selected = event.currentTarget.dataset['key'] ?? '';
      const category = event.currentTarget.dataset['category'] as Category;
      const { x, y } = localPoint(event) || { x: 0, y: 0 };
      const x0 = timeScale.invert(x);
      const index = bisectDate(data, x0, 1);
      const d0 = data[index - 1];
      const d1 = data[index];
      let first = true;
      if (d1 && getDate(d1)) {
        first = x0.valueOf() - getDate(d0).valueOf() < getDate(d1).valueOf() - x0.valueOf();
      }

      const date = x0.toISOString().slice(0, 7);
      const assignement = 100 * (getValue(category)(first ? d0 : d1) ?? 0);
      showTooltip({
        tooltipData: {
          selected,
          date,
          assignement: assignement.toFixed(2),
        },
        tooltipLeft: timeScale(getDate(first ? d0 : d1)) ?? 0,
        tooltipTop: valueScale(getValue(category)(first ? d0 : d1) ?? 0),
      });
    },
    [data, showTooltip, timeScale, valueScale]
  );

  // Retrieve all categories from the passed data
  // We use a Set to remove duplicated values
  const categories = Array.from(
    data.flatMap((d) => Object.keys(d.values ?? {}) as Category[]).reduce((acc, el) => acc.add(el), new Set<Category>())
  );

  return (
    <div style={{ position: 'relative', width: 'inherit', height: 'inherit' }}>
      <svg width={svgWidth} height={svgHeight} data-testid="focus-area-chart">
        <Group left={margin.left} top={margin.top}>
          <GridRows scale={valueScale} width={width} height={height} stroke="#DDDEE9" strokeWidth={2} />
          <g data-testid="axis-x">
            <AxisBottom
              top={height}
              scale={timeScale}
              tickFormat={formatDate}
              tickLabelProps={{
                fontFamily: 'Montserrat',
                fontSize: '15px',
                fontWeight: 400,
                fill: '#51537B',
              }}
              hideAxisLine
              hideTicks
              numTicks={data.length}
            />
          </g>
          <g data-testid="axis-y">
            <AxisLeft
              scale={valueScale}
              tickFormat={formatPercentage}
              tickLabelProps={{
                fontFamily: 'Montserrat',
                fontSize: '15px',
                fontWeight: 400,
                fill: '#51537B',
              }}
              hideAxisLine
              hideTicks
            />
          </g>
          {categories
            .map((category) => ({
              category,
              // We retrive the configuration color assigned to a category and propagate it in the next steps
              color: ASSIGNMENTS_BY_FOCUS_AREA_PARAMS.find((p) => p.i18key === category)?.color,
            }))
            .map(({ category, color }) => (
              // let's group the path a points in a Group object
              <Group key={category}>
                <LinePath
                  data={data}
                  x={(d) => timeScale(getDate(d))}
                  y={(d) => valueScale(getValue(category)(d) ?? 0)}
                  stroke={color}
                />
                {data
                  .map((d) => ({
                    key: `${category}-${d.date}`,
                    date: getDate(d),
                    value: getValue(category)(d) ?? 0,
                  }))
                  .map((d) => {
                    const selected = d.key === tooltipData?.selected;
                    return (
                      <circle
                        key={d.key}
                        cx={timeScale(d.date)}
                        cy={valueScale(d.value!)}
                        r={5}
                        fill={color}
                        stroke={selected ? color : 'none'}
                        opacity={selected ? 1 : 0.8}
                        strokeWidth={3}
                        onTouchStart={handleTooltip}
                        onTouchMove={handleTooltip}
                        onMouseMove={handleTooltip}
                        onMouseLeave={hideTooltip}
                        data-category={category}
                        data-key={d.key}
                        data-testid="graph-dot"
                      />
                    );
                  })}
              </Group>
            ))}
        </Group>
      </svg>
      {tooltipData && (
        <TooltipWithBounds
          // key="tooltip"
          key={Math.random()}
          top={tooltipTop! - 50}
          left={tooltipLeft!}
          data-testid="tooltip"
          style={{
            padding: '10px',
            backgroundColor: '#313391',
            color: 'white',
            position: 'absolute',
            borderRadius: '5px',
            fontSize: '12px',
            fill: '#51537b',
            fontFamily: 'Montserrat',
            fontWeight: 400,
            lineHeight: '18px',
            letterSpacing: '0px',
            textAlign: 'left',
            alignmentBaseline: 'middle',
          }}
        >
          {t('engagement.academy.distribution.legend.timeline')}: <strong>{tooltipData?.date}</strong>
          <br />
          {t('engagement.academy.distribution.legend.assignments')}: <strong>{tooltipData?.assignement}</strong>
        </TooltipWithBounds>
      )}
    </div>
  );
};

function getDate(data: FocusAreaData): number {
  return new Date(`${data.date}-01`).valueOf();
}
const bisectDate = bisector<FocusAreaData, Date>(getDate).left;

function getValue(key: keyof FocusAreaCategoryType) {
  return function (data: FocusAreaData) {
    return data.values[key] ?? null;
  };
}
const formatDate = (date: Date | NumberValue) => {
  let dt: Date;
  if (typeof date === 'number') {
    dt = new Date(date);
  } else if ('valueOf' in date) {
    dt = new Date(date.valueOf());
  } else {
    dt = date;
  }
  return dt.toISOString().slice(0, 7);
};
const formatPercentage = (time: NumberValue) => {
  const v = typeof time === 'object' ? time.valueOf() : time;
  return `${Math.round(v * 100)} %`;
};

export default EngagementLearningAcademyAssignmentsByFocusAreaGraph;
