import React from 'react';

import { getBorder } from '@coachhubio/nova-borders';
import { ArrowDownIcon, ArrowUpIcon, InfoIcon, LightBulbIcon } from '@coachhubio/nova-icon';
import { Skeleton } from '@coachhubio/nova-skeleton';
import { marginTop, padding } from '@coachhubio/nova-spaces';
import { gap } from '@coachhubio/nova-spaces';
import { Tag } from '@coachhubio/nova-tag';
import { Heading, Text } from '@coachhubio/nova-text';
import DOMPurify from 'dompurify';
import useGetLookerData from 'hooks/useGetLookerData';
import { useLocale } from 'hooks/useLocale';
import ReactHtmlParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { LookerFieldsTag } from 'screens/Dashboard/utils/lookerFields';
import styled from 'styled-components';

import { Row, Column, Placeholder, SubTitle, InlinePlaceholder, BrandTag } from '../../components/Common';

const GraphAvrgSession = styled.div`
  width: 100%;

  ${padding('l')}
  ${getBorder({ color: 'neutral80', width: 's', radius: 'm' })}

  @media (min-width: 1390px) {
    width: 35%;
  }
`;

const SessioncountCol = styled(Column)`
  ${gap('s')}

  & ${LightBulbIcon} {
    ${marginTop('m')}

    width: 32px;
    height: 32px;
  }
`;

const SessioncountRow = styled(Row)`
  ${marginTop('m')}

  & svg {
    width: 44px;
    height: 44px;
  }
`;

export interface OverviewEngagementAvgSession {
  'avg_session.avg_sessions_completed': number;
  average_of_benchmark_metric_value: number;
  diff_between_session_and_benchmark: number;
  sessions: number;
  benchmark: number;
  insight: string;
}

export function GraphAverageSession() {
  const { t } = useTranslation('Dashboard');
  const locale = useLocale();
  const { loading, data = [] } = useGetLookerData<OverviewEngagementAvgSession[]>(
    LookerFieldsTag.OverviewEngagementAvgSession
  );

  const sessionCount =
    data && data.length && data[0]['avg_session.avg_sessions_completed']
      ? new Intl.NumberFormat(locale).format(Number(data[0]['avg_session.avg_sessions_completed'].toFixed(2)))
      : undefined;

  const sessionBenchmark =
    data && data.length && data[0]['average_of_benchmark_metric_value']
      ? new Intl.NumberFormat(locale).format(Number(data[0]['average_of_benchmark_metric_value'].toFixed(2)))
      : undefined;

  const sessionBenchmarkPositive =
    data && data.length && data[0]['diff_between_session_and_benchmark']
      ? data[0]['diff_between_session_and_benchmark'] > 0
      : undefined;

  return (
    <GraphAvrgSession>
      <SessioncountCol>
        <SubTitle>{t('overview.engagement.avg_sessions.title')}</SubTitle>
        <SessioncountRow>
          {!loading ? (
            <>
              {sessionCount !== undefined ? (
                <>
                  <Heading color="primaryDark35" size="xl">
                    {sessionCount}
                  </Heading>
                  {sessionBenchmarkPositive ? <ArrowUpIcon color="success35" /> : <ArrowDownIcon color="error40" />}
                </>
              ) : (
                <Heading color="primaryDark35" size="xl">
                  {t(`common.no_data`)}
                </Heading>
              )}
            </>
          ) : (
            <Placeholder $height="44px" $width="100px">
              <Skeleton height="44px" width="100px" />
            </Placeholder>
          )}
        </SessioncountRow>
        <Text size="s" modifier="bold" color="primaryDark25">
          {t('overview.engagement.avg_sessions.description')}
        </Text>
        {loading ? (
          <InlinePlaceholder $height="16px" $width="120px">
            <Skeleton height="16px" width="120px" />
          </InlinePlaceholder>
        ) : sessionBenchmark ? (
          <BrandTag>{t('overview.satisfaction.avg_session.benchmark', { number: sessionBenchmark })}</BrandTag>
        ) : (
          <BrandTag variant="hint">{t('common.no_data')}</BrandTag>
        )}
        <Tag iconPosition="start" variant="hint" Icon={InfoIcon}>
          {t('overview.engagement.avg_sessions.info')}
        </Tag>
        <LightBulbIcon color="primaryDark25" />
        {loading ? (
          <InlinePlaceholder $height="80px" $width="100%">
            <Skeleton height="80px" width="100%" />
          </InlinePlaceholder>
        ) : sessionBenchmarkPositive !== undefined ? (
          <Text color="primaryDark25" size="s">
            <>
              {sessionBenchmarkPositive
                ? ReactHtmlParser(DOMPurify.sanitize(t(`overview.engagement.avg_sessions.insight.positive`)))
                : ReactHtmlParser(DOMPurify.sanitize(t(`overview.engagement.avg_sessions.insight.negative`)))}
            </>
          </Text>
        ) : (
          <Text color="primaryDark25" size="s">
            {t('common.no_data')}
          </Text>
        )}
      </SessioncountCol>
    </GraphAvrgSession>
  );
}
