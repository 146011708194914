import React, { useState, useRef } from 'react';

import { ColorAlias, getBackgroundColor } from '@coachhubio/nova-colors';
import { InfoIcon } from '@coachhubio/nova-icon';
import { gap } from '@coachhubio/nova-spaces';
import { Text } from '@coachhubio/nova-text';
import { Tooltip } from '@coachhubio/nova-tooltip';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Row } from '../../components/Common';

const LegendContainer = styled(Row)`
  align-self: flex-end;
  flex-grow: 0;

  ${gap('l')}
`;

const Indicator = styled.div<{ $color: ColorAlias; $bar?: boolean }>`
  height: ${({ $bar }) => ($bar ? '2' : '8')}px;
  width: 12px;
  border-radius: 2px;

  ${({ $color }) => getBackgroundColor($color)}
`;

const IconWrapper = styled(Row)`
  align-items: center;
`;

const LegendWrapper = styled(IconWrapper)`
  ${gap('xs')}
`;

const LegendTextWrap = styled.div`
  max-width: 200px;
`;

function Legend({ color, i18nKey }: { color: ColorAlias; i18nKey: string }) {
  const { t } = useTranslation('Dashboard');
  const tooltipParentRef = useRef<HTMLDivElement | null>(null);

  const [active, setActive] = useState(false);

  const handleHover = (val: boolean) => {
    return () => setActive(val);
  };

  return (
    <LegendWrapper ref={tooltipParentRef}>
      <Indicator $color={color} $bar={i18nKey === 'goal'} />
      <Text size="xs">{t(`impact.progress.focusarea.legend.${i18nKey}`)}</Text>
      <IconWrapper onMouseEnter={handleHover(true)} onMouseLeave={handleHover(false)}>
        <InfoIcon size="xs" color="neutral60" />
      </IconWrapper>
      <Tooltip active={active} parentRef={tooltipParentRef.current} placement="top" variant="dark" withArrow>
        <LegendTextWrap>
          <Text size="xs">{t(`impact.progress.focusarea.legend.${i18nKey}_desc`)}</Text>
        </LegendTextWrap>
      </Tooltip>
    </LegendWrapper>
  );
}

const ProgressGoalsLegend = () => {
  return (
    <LegendContainer>
      <Legend color="primaryDark85" i18nKey="initial" />
      <Legend color="primaryDark70" i18nKey="latest" />
      <Legend color="success35" i18nKey="goal" />
    </LegendContainer>
  );
};

export default ProgressGoalsLegend;
