import { useMemo } from 'react';

import { Skeleton } from '@coachhubio/nova-skeleton';
import { Tag } from '@coachhubio/nova-tag';
import useGetLookerData from 'hooks/useGetLookerData';
import { useTranslation } from 'react-i18next';
import { Placeholder } from 'screens/Dashboard/components/Common';
import { SectionHeader } from 'screens/Dashboard/components/SectionHeader';
import { LookerFieldsTag } from 'screens/Dashboard/utils/lookerFields';

import { HeaderCol } from './Styles';

type HeaderProps = {} & { [dataAttribute: `data-${string}`]: string };

type FeedbackCount = {
  count_of_feedback: number;
};

export default function Header(props: HeaderProps) {
  const { t } = useTranslation('Dashboard');

  const { loading, data } = useGetLookerData<FeedbackCount[]>(LookerFieldsTag.SatisfactionFeedbackCount);

  const count = useMemo(() => {
    if (Array.isArray(data) && data.length > 0) {
      const { count_of_feedback: count } = data[0];
      if (['string', 'number'].includes(typeof count)) {
        return Number(count);
      }
    }
    return null;
  }, [data]);

  return (
    <HeaderCol {...props}>
      <SectionHeader
        i18title={'satisfaction.coacheesessionfeedback.title'}
        i18description={'satisfaction.coacheesessionfeedback.description'}
        position="start"
      />
      {!loading ? (
        count ? (
          <Tag variant="hint">
            {t('satisfaction.coacheesessionfeedback.feedbackcount', {
              count: count,
            })}
          </Tag>
        ) : (
          <Tag variant="hint">{t(`common.no_data`)}</Tag>
        )
      ) : (
        <Placeholder data-testid="placeholder" $height="16px" $width="250px">
          <Skeleton height="16px" width="250px" />
        </Placeholder>
      )}
    </HeaderCol>
  );
}
