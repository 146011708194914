import React from 'react';

import { gap } from '@coachhubio/nova-spaces';
import { GraphActivationJourney } from 'screens/Dashboard/Overview/components/GraphActivationJourney';
import styled from 'styled-components';

import { Row, Card, SmallCard, Column } from '../../components/Common';

import { CoacheesJourneyStatus, CoacheesAdoptionCard } from './';

export const GraphRow = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  min-height: 160px;

  ${gap('l')}

  @media (min-width: 1390px) {
    flex-wrap: nowrap;
  }
`;

export const ColumnGap = styled(Column)`
  ${gap('l')}
`;

const CoachingJourneyGraphs = () => {
  return (
    <ColumnGap data-testid="coaching-journey-graphs">
      <GraphRow>
        <Card>
          <CoacheesJourneyStatus />
        </Card>
      </GraphRow>
      <GraphRow>
        <GraphActivationJourney />
        <SmallCard>
          <CoacheesAdoptionCard />
        </SmallCard>
      </GraphRow>
    </ColumnGap>
  );
};

export default CoachingJourneyGraphs;
