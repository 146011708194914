import React from 'react';
import type { ReactElement } from 'react';

import { getBorder } from '@coachhubio/nova-borders';
import { Skeleton } from '@coachhubio/nova-skeleton';
import { padding, paddingX } from '@coachhubio/nova-spaces';
import { Heading, Text } from '@coachhubio/nova-text';
import DOMPurify from 'dompurify';
import ReactHtmlParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { InlinePlaceholder } from 'screens/Dashboard/components/Common';
import { DownloadDropdownIconButton } from 'screens/Dashboard/components/DownloadDropdownIconButton';
import { LookerFieldsTag } from 'screens/Dashboard/utils/lookerFields';
import styled from 'styled-components';

import useGetLookerData from '../../../../hooks/useGetLookerData';
import { IStrengthData } from '../types';
import HorizontalBarChart from './HorizontalBarChart';

const CardContainer = styled.div`
  width: 100%;

  ${padding('l')}
  ${getBorder({ color: 'neutral80', width: 's', radius: 'l' })}

  @media (min-width: 1390px) {
    width: 50%;
  }
`;

const HeadingContainer = styled.div`
  display: flex;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
`;

const Subtext = styled(Text)`
  color: #51537b;
  margin-top: 11px;

  ${paddingX('m')}
`;

const DownloadButtonContainer = styled.div`
  display: flex;
  align-self: center;
`;

const GraphContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const csvColumns = {
  title: {
    lookerKey: 'assessments.title',
    i18titleKey: 'impact.table.areas.improvement.title',
  },
  coachees: {
    lookerKey: 'coachee.m_coachees',
    i18titleKey: 'impact.table.coachees.number',
  },
};

const AreasForImprovementCard = (): ReactElement => {
  const { t } = useTranslation('Dashboard');

  const { data = [], loading } = useGetLookerData<IStrengthData[]>(LookerFieldsTag.AreasForImprovement);

  return (
    <CardContainer>
      <HeadingContainer>
        <TextContainer>
          <Heading color="brandDark" size="m">
            {t('impact.assessments.strengths.bottom.title')}
          </Heading>
          <Subtext size="xs" type="text">
            {ReactHtmlParser(DOMPurify.sanitize(t('impact.assessments.strengths.bottom.description')))}
          </Subtext>
        </TextContainer>
        <DownloadButtonContainer>
          <DownloadDropdownIconButton
            lookName={LookerFieldsTag.AreasForImprovement}
            option="csv"
            columns={{
              [csvColumns.title.lookerKey]: t(csvColumns.title.i18titleKey),
              [csvColumns.coachees.lookerKey]: t(csvColumns.coachees.i18titleKey),
            }}
          />
        </DownloadButtonContainer>
      </HeadingContainer>
      <GraphContainer>
        {data.length === 0 && !loading ? (
          <Heading color="brandDark" size="l">
            {t(`common.no_data`)}
          </Heading>
        ) : (
          <>
            {loading ? (
              <InlinePlaceholder $height="300px" $width="500px">
                <Skeleton height="300px" width="500px" />
              </InlinePlaceholder>
            ) : (
              <HorizontalBarChart data={data} type="improvement" />
            )}
          </>
        )}
      </GraphContainer>
    </CardContainer>
  );
};

export default AreasForImprovementCard;
