import React, { RefObject } from 'react';

import { DropdownButton } from '@coachhubio/nova-dropdown-button';
import { DownloadIcon } from '@coachhubio/nova-icon';
import { useLooker } from 'context/LookerContext';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useTranslation } from 'react-i18next';
import { getReport } from 'utils/fetchDashboard';

type option = 'both' | 'csv' | 'csx';

type Props = {
  className?: string;
  option?: option;
  lookName?: string;
  columns?: any;
  element?: RefObject<HTMLDivElement>;
};

const downloadPdf = async (element: RefObject<HTMLDivElement> | undefined) => {
  if (element && (element?.current === undefined || element?.current === null)) return;
  const w = element?.current?.offsetWidth || window.innerWidth;
  const h = element?.current?.offsetHeight || window.innerHeight;

  const canvas = await html2canvas(element?.current as any, { useCORS: true });

  const pdf = new jsPDF('p', 'mm', [w, h]);
  pdf.addImage(canvas, 'JPEG', 10, 10, w, h);
  pdf.save('report.pdf');
};

export function DownloadDropdownIconButton({
  className,
  option = 'both',
  lookName = '',
  columns = {},
  element,
}: Props) {
  const { t } = useTranslation('Dashboard');
  const { filters, looker } = useLooker();

  const items = [
    {
      type: 'pdf',
      label: t('overview.engagement.download.PDF'),
      onClick: () => downloadPdf(element),
    },
    {
      type: 'csv',
      label: t('overview.licenses.download.csv'),
      onClick: () => getReport(lookName, looker, filters, columns),
    },
  ];

  const downloadMenu = [
    {
      items: items.filter((i) => option === 'both' || option === i.type),
    },
  ];

  return (
    <DropdownButton
      withChevron={false}
      menuItems={downloadMenu}
      buttonVariant="icon"
      buttonSize="s"
      autoFlip
      className={className}
    >
      <DownloadIcon />
    </DropdownButton>
  );
}
