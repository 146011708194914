import { LookerConfig } from 'hooks/useInitializeLooker';
import { getI18n } from 'react-i18next';
import { fetchApi } from 'utils/fetchApi';

type Filters =
  | 'benchmark_types'
  | 'career_levels'
  | 'genders'
  | 'organizations'
  | 'programs'
  | 'countries'
  | 'data_granularity'
  | 'data_range';
export type FiltersData = Partial<Record<Filters, string>>;
export type FiltersOptions = Record<Filters, { value: string; label: string }[]>;

const getLookIdByName = (looks: Array<any>, lookName: string): string => {
  const look = looks && looks.find((r) => r['hr_dashboard_looks_list.look_name'] === lookName);
  if (!look) throw new Error(`Looker tag ${lookName} doesn't exists`);

  return look['hr_dashboard_looks_list.look_id'];
};

export const getData = async function (
  lookName: string,
  lookerConfig: LookerConfig,
  filters: FiltersData,
  sorts: string[] = [],
  extraFilters?: { [key: string]: string | string[] }
): Promise<any> {
  const { looks, token } = lookerConfig || {};
  const lookId = getLookIdByName(looks, lookName);
  try {
    const { language } = getI18n();
    const res = await fetchApi('/api/dashboard/data', {
      method: 'POST',
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`,
        'Accept-Language': language,
      },
      body: JSON.stringify({
        lookId,
        filters: {
          ...filters,
          ...(extraFilters || {}),
        },
        sorts,
      }),
    });

    const resData = await res.json();

    return resData?.data ? resData?.data : undefined;
  } catch (e) {
    console.log(e);
  }
};

export const getReport = async function (
  lookName: string,
  lookerConfig: LookerConfig | undefined,
  filters: FiltersData | undefined,
  columns: any
): Promise<any> {
  const { looks, token } = (lookerConfig || {}) as LookerConfig;
  const lookId = getLookIdByName(looks, lookName);

  // Retrieve current language
  const { language } = getI18n();

  const res = await fetchApi('/api/dashboard/report', {
    method: 'POST',
    headers: {
      Authorization: `${token.token_type} ${token.access_token}`,
      'Accept-Language': language,
    },
    body: JSON.stringify({
      lookId,
      filters,
      columns,
    }),
  });

  const csvContent = await res.text();

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', lookName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export async function getLookerFilters(looker: LookerConfig): Promise<FiltersOptions | undefined> {
  const { token } = looker || {};
  try {
    const res = await fetchApi('/api/dashboard/filters', {
      method: 'GET',
      headers: {
        Authorization: `${token!.token_type} ${token!.access_token}`,
      },
    });

    const { filters } = (await res.json()) as { filters: FiltersOptions };
    return filters;
  } catch (e) {
    console.log(`Error getting Looker filters ${e}`);
  }
}
