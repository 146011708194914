import React from 'react';

import { gap } from '@coachhubio/nova-spaces';
import useGetLookerData from 'hooks/useGetLookerData';
import { LookerFieldsTag } from 'screens/Dashboard/utils/lookerFields';
import styled from 'styled-components';

import StatusCard from './StatusCard';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  ${gap('s')};
`;

type JourneyCoacheeStatus = {
  'coachee.m_coachees': number;
  'coachee.m_coachee_invited': number;
  'coachee.m_coachee_matching': number;
  'coachee.m_coachee_matched': number;
  'coachee.m_coachee_active': number;
  'coachee.m_coachee_paused': number;
  'coachee.m_coachee_completed': number;
  pct_invited: number;
  pct_matching: number;
  pct_matched: number;
  pct_active: number;
  pct_completed: number;
  coachees_invited: number;
  coachees_matching: number;
  coachee_matched: number;
  coachees_active: number;
  coachees_completed: number;
};

type CanceledCoachees = {
  'coachee.m_coachees': number;
};

const CoacheesJourneyStatus = () => {
  const { data = [], loading } = useGetLookerData<JourneyCoacheeStatus[]>(
    LookerFieldsTag.EngagementJourneyCoacheeStatus
  );
  const { data: coacheesCanceledData = [], loading: coacheesCanceledLoading } = useGetLookerData<CanceledCoachees[]>(
    'engagement_coaching_journey_cancelled_coachees'
  );

  const coachingJourneyCoacheesStatus = data[0];

  return (
    <Grid>
      <StatusCard
        isLoading={loading}
        i18title="overview.engagement.coachee_activation.legend.invited"
        i18information="engagement.journey.status.total_percentage"
        i18tooltip="engagement.journey.status.invited.tooltip"
        count={coachingJourneyCoacheesStatus?.['coachee.m_coachee_invited']}
        informationCount={coachingJourneyCoacheesStatus?.pct_invited}
        tooltipProps={{ placement: 'top-start' }}
        href="/coachee-list?status=invited"
      />
      <StatusCard
        isLoading={loading}
        i18title="overview.engagement.coachee_activation.legend.matching"
        i18information="engagement.journey.status.total_percentage"
        i18tooltip="engagement.journey.status.matching.tooltip"
        count={coachingJourneyCoacheesStatus?.['coachee.m_coachee_matching']}
        informationCount={coachingJourneyCoacheesStatus?.pct_matching}
        href="/coachee-list?status=matching"
      />
      <StatusCard
        isLoading={loading}
        i18title="overview.engagement.coachee_activation.legend.matched"
        i18information="engagement.journey.status.total_percentage"
        i18tooltip="engagement.journey.status.matched.tooltip"
        count={coachingJourneyCoacheesStatus?.['coachee.m_coachee_matched']}
        informationCount={coachingJourneyCoacheesStatus?.pct_matched}
        href="/coachee-list?status=matched"
      />
      <StatusCard
        isLoading={loading}
        i18title="overview.engagement.coachee_activation.legend.active"
        i18information="engagement.journey.status.total_percentage"
        i18tooltip="engagement.journey.status.active.tooltip"
        count={coachingJourneyCoacheesStatus?.['coachee.m_coachee_active']}
        informationCount={coachingJourneyCoacheesStatus?.pct_active}
        href="/coachee-list?status=active"
      />
      <StatusCard
        isLoading={loading}
        i18title="overview.engagement.coachee_activation.legend.completed"
        i18information="engagement.journey.status.total_percentage"
        i18tooltip="engagement.journey.status.completed.tooltip"
        count={coachingJourneyCoacheesStatus?.['coachee.m_coachee_completed']}
        informationCount={coachingJourneyCoacheesStatus?.pct_completed}
        href="/coachee-list?status=completed"
      />
      <StatusCard
        isLoading={coacheesCanceledLoading}
        i18title="engagement.journey.status.cancelled"
        i18information="engagement.journey.status.not_included"
        i18tooltip="engagement.status.cancelled.tooltip"
        count={coacheesCanceledData[0]?.['coachee.m_coachees']}
        informationCount={-1}
        href="/coachee-list?status=cancelled"
      />
    </Grid>
  );
};

export default CoacheesJourneyStatus;
