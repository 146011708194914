import React, { useRef } from 'react';
import type { ReactElement, ReactNode } from 'react';

import { CoachingLicensesProvider } from 'context/CoachingLicensesRef';
import { useRouter } from 'next/router';

import { Wrapper } from '../components/Common';
import { DashboardTitle } from '../components/DashboardTitle';
import { sectionNames } from '../utils/dashboardSideBar';
import { Filters } from './../components/Filters';
import { SectionBanner } from './SectionBanner';

type Props = {
  children: ReactNode;
  className?: string;
};

const LookerDashboardLayout = function ({ children }: Props): ReactElement {
  const ref = useRef<HTMLDivElement>(null);

  const router = useRouter();
  const page = router.route.replace(/\//g, '') || sectionNames[0];

  return (
    <CoachingLicensesProvider>
      <Wrapper ref={ref}>
        <DashboardTitle />
        <Filters />
        <SectionBanner page={page} pdfDonwloadRef={ref} />
        {children}
      </Wrapper>
    </CoachingLicensesProvider>
  );
};

export default LookerDashboardLayout;
