import { getBorder, borderRadius } from '@coachhubio/nova-borders';
import { getBackgroundColor, getColor } from '@coachhubio/nova-colors';
import { marginBottom, margin, padding, gap } from '@coachhubio/nova-spaces';
import { Tag } from '@coachhubio/nova-tag';
import { Text } from '@coachhubio/nova-text';
import { getTextStyle } from '@coachhubio/nova-typography';
import styled from 'styled-components';

export const Placeholder = styled.div<{ $height: string; $width: string }>`
  ${borderRadius('m')}
  ${getBackgroundColor('neutral95')}

  ${({ $height }) => `height: ${$height};`}
  ${({ $width }) => `width: ${$width};`}
`;

export const InlinePlaceholder = styled(Placeholder)`
  display: inline-block;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  min-height: 100vh;
  width: 100%;
  background-color: white;

  ${padding('xl')}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const CenteredRow = styled(Row)`
  justify-content: center;
`;

export const Title = styled.h1`
  display: flex;
  justify-content: space-between;

  ${getTextStyle('heading', 'l', 'bold')}
  ${getColor('primaryDark35')}
  ${margin('none')}
`;

export const SubTitle = styled.h2`
  ${getTextStyle('heading', 'm', 'bold')}
  ${getColor('primaryDark35')}
  ${margin('none')}
  ${marginBottom('xs')}
`;

export const Description = styled(Text)`
  ${marginBottom('s')}
`;

export const CenteredText = styled(Text)`
  text-align: center;
`;

export const ClickableTag = styled(Tag)`
  cursor: pointer;
`;

export const BrandTag = styled(Tag)`
  ${getColor('brandMain')}
  ${getBackgroundColor('secondaryMain95')}
`;

export const Card = styled.div`
  width: 100%;

  ${padding('l')}
  ${getBorder({ color: 'neutral80', width: 's', radius: 'm' })}
`;

export const BigCard = styled(Card)`
  width: 100%;
  @media (min-width: 1390px) {
    width: 65%;
  }
`;

export const SmallCard = styled(Card)`
  height: 100%;
  @media (min-width: 1390px) {
    width: 35%;
  }
`;

export const CenteredColumn = styled(Column)`
  justify-content: center;
  align-items: center;
`;

export const CardHeader = styled(Row)`
  justify-content: space-between;
  align-items: center;
`;

export const ColumnWithoutFlex = styled(Column)`
  flex-grow: 0;
`;

export const GraphsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${gap('l')}

  @media (min-width: 1390px) {
    flex-wrap: nowrap;
  }
`;
