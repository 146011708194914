import React, { RefObject, ReactNode } from 'react';

import { ButtonIcon } from '@coachhubio/nova-button';
import { DropdownButton } from '@coachhubio/nova-dropdown-button';
import { StyledIcon } from '@coachhubio/nova-icon';
import { useLooker } from 'context/LookerContext';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useTranslation } from 'react-i18next';
import { getReport } from 'utils/fetchDashboard';

declare type option = 'both' | 'csv' | 'pdf';

export type DownloadDropdownButtonProps = {
  className?: string;
  option?: option;
  lookName?: string;
  columns?: any;
  element?: RefObject<HTMLDivElement>;
  customFilters?: Record<string, string>;
  [dataAttribute: `data-${string}`]: string;
} & (
  | {
      children?: undefined;
      icon: StyledIcon;
    }
  | {
      children: ReactNode;
      icon?: undefined;
    }
);

export function DownloadDropdownButton({
  className,
  option = 'both',
  lookName = '',
  columns = {},
  element = undefined,
  icon,
  customFilters,
  children,
  ...props
}: DownloadDropdownButtonProps) {
  const { t } = useTranslation('Dashboard');
  const { filters, looker } = useLooker();

  const downloadPdf = async () => {
    if (element && (element?.current === undefined || element?.current === null)) return;
    var w = element?.current?.offsetWidth || window.innerWidth;
    var h = element?.current?.offsetHeight || window.innerHeight;

    const canvas = await html2canvas(element?.current as any, { useCORS: true });

    var pdf = new jsPDF('p', 'mm', [w, h]);
    pdf.addImage(canvas, 'JPEG', 10, 10, w, h);
    pdf.save('report.pdf');
  };

  const handlers = {
    pdf: () => downloadPdf(),
    csv: () => getReport(lookName, looker, customFilters ?? filters, columns),
  };

  const items = [
    {
      type: 'pdf',
      label: t('overview.engagement.download.PDF'),
      onClick: handlers['pdf'],
    },
    {
      type: 'csv',
      label: t('overview.licenses.download.csv'),
      onClick: handlers['csv'],
    },
  ];

  const downloadMenu = [
    {
      items: items.filter((i) => option === 'both' || option === i.type),
    },
  ];

  if (icon) {
    if (option === 'both') {
      return (
        <DropdownButton
          {...props}
          menuItems={downloadMenu}
          buttonVariant="secondary"
          buttonSize="s"
          className={className}
        />
      );
    }
    return <ButtonIcon Icon={icon} onClick={handlers[option]} />;
  }

  return (
    <DropdownButton {...props} menuItems={downloadMenu} buttonVariant="secondary" buttonSize="s" className={className}>
      {children}
    </DropdownButton>
  );
}
