import React from 'react';

// import { DropdownButton } from '@coachhubio/nova-dropdown-button';
import { marginBottom } from '@coachhubio/nova-spaces';
import { Heading } from '@coachhubio/nova-text';
import { useLooker } from 'context/LookerContext';
import { useTranslation } from 'react-i18next';
import { Card, GraphsContainer } from 'screens/Dashboard/components/Common';
import styled from 'styled-components';

import { ColumnWithoutFlex } from '../../../Dashboard/components/Common';

import { AverageSessionsScoreBars, EngagementAverageSessionChat } from './';

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  width: 100%;
  text-align: center;

  ${marginBottom('3xl')}
`;

const AverageSessionsSection = () => {
  const { t } = useTranslation('Dashboard');

  const { filters, looker } = useLooker();

  return (
    <ColumnWithoutFlex>
      <TitleRow id="row">
        <TitleContainer>
          <Heading color="brandDark" size="l">
            {t('engagement.avg_session.title')}
          </Heading>
        </TitleContainer>
      </TitleRow>
      <GraphsContainer>
        <Card>
          <AverageSessionsScoreBars />
        </Card>
        <Card>
          <EngagementAverageSessionChat lookerConfig={looker} filtersAPI={filters} />
        </Card>
      </GraphsContainer>
    </ColumnWithoutFlex>
  );
};

export default AverageSessionsSection;
