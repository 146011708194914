import React, { useState } from 'react';

import { getBorder } from '@coachhubio/nova-borders';
import { Button } from '@coachhubio/nova-button';
import { FaceHappyIcon, FaceSadIcon } from '@coachhubio/nova-icon';
import { marginBottom, marginEnd, marginStart, padding, paddingStart } from '@coachhubio/nova-spaces';
import { Text } from '@coachhubio/nova-text';
import { useLocale } from 'hooks/useLocale';
import { useTranslation } from 'react-i18next';
import { Column, Row } from 'screens/Dashboard/components/Common';
import styled from 'styled-components';
import { localizedDate, localizedNumber } from 'utils/i18n';

const ListRow = styled(Row)`
  min-height: 116px;

  ${marginBottom('base')}
  ${padding('base')}
  ${paddingStart('m')}
  ${getBorder({ color: 'neutral85', width: 's', radius: 'l' })}
`;

const IconWrapper = styled.div`
  ${marginEnd('base')}
`;

const TextButton = styled(Button)`
  display: inline-flex;

  ${marginStart('xs')}
`;

export type FeedbackItem = {
  'coachee_sessions.feedback': string;
  'coachee_sessions.m_avg_session_ratings': number;
  'coachee_sessions.startsat_date': string;
  new_calculation_1: number;
};

type FeedbackProps = {
  feedback: FeedbackItem;
  [dataAttribute: `data-${string}`]: string;
};

const Feedback = ({ feedback, ...props }: FeedbackProps) => {
  const { t } = useTranslation('Dashboard');
  const locale = useLocale();

  const [showMore, setMore] = useState(false);

  const {
    'coachee_sessions.feedback': text,
    'coachee_sessions.m_avg_session_ratings': rating,
    'coachee_sessions.startsat_date': date,
  } = feedback;

  const truncatedText = text.length > 200 ? [...text].slice(0, 200) : false;
  const hiddenText = truncatedText ? [...text].slice(200) : '';
  const localizedRating = localizedNumber({ locale, value: rating });

  return (
    <ListRow {...props}>
      <IconWrapper>
        {rating >= 4 ? <FaceHappyIcon size="xl" color="neutral50" /> : <FaceSadIcon size="xl" color="neutral50" />}
      </IconWrapper>
      <Column>
        <Text modifier="bold" color="primaryDark35">{`${localizedRating}/5`}</Text>
        <Text size="s" modifier="bold" color="primaryDark35">
          {localizedDate({ locale, date })}
        </Text>
        <Text size="s" color="primaryDark35" data-testid="trunc-text">
          {!truncatedText ? (
            text
          ) : (
            <>
              {truncatedText}
              {!showMore ? (
                <>
                  {`...`}
                  <TextButton variant="text" size="xs" onClick={() => setMore(true)}>
                    <Text size="s" modifier="bold">
                      {t('satisfaction.coacheesessionfeedback.more')}
                    </Text>
                  </TextButton>
                </>
              ) : (
                hiddenText
              )}
            </>
          )}
        </Text>
      </Column>
    </ListRow>
  );
};

export default Feedback;
