import React, { useState, RefObject } from 'react';
import type { ReactElement } from 'react';

import { Banner } from '@coachhubio/nova-banner';
import { Button } from '@coachhubio/nova-button';
import { getBackgroundColor } from '@coachhubio/nova-colors';
import { gap, marginBottom, marginTop, padding } from '@coachhubio/nova-spaces';
import { Heading, Text } from '@coachhubio/nova-text';
import { useTranslation } from 'react-i18next';
import { Row } from 'screens/Dashboard/components/Common';
import styled from 'styled-components';

import GoalSettingLight from '../../../../assets/goal-setting-light.svg';
import { DownloadDropdownButton } from '../../components/DownloadDropdownButton';
import { SectionHeader } from '../../components/SectionHeader';

const EngagementBannerContainer = styled.div`
  ${marginBottom('2xl')}
`;

const CustomBanner = styled(Banner)`
  display: flex;
  width: 90%;

  ${gap('base')}
`;

const IconContainer = styled.div`
  display: flex;
  width: 64px;
  height: 64px;

  ${padding('xs')}
`;

const DownloadButton = styled(DownloadDropdownButton)`
  align-self: center;
`;

const BannerContent = styled.div`
  margin-inline-start: 16px;
`;

const BannerTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BannerButton = styled(Button)`
  ${marginTop('l')}
  ${getBackgroundColor('transparent')}
`;

const EngagementBanner = ({ wrapperRef }: { wrapperRef: RefObject<HTMLDivElement> }): ReactElement => {
  const { t } = useTranslation('Dashboard');
  const title = t('overview.engagement.title');
  const description = t('overview.engagement.description');

  const [showEngagementBanner, setShowEngagementBanner] = useState<boolean>(true);

  const closeBanner = () => {
    setShowEngagementBanner(false);
  };

  return (
    <EngagementBannerContainer>
      <Row>
        <SectionHeader i18title="overview.engagement.title" position="start" withIcon />
        <DownloadButton option="pdf" element={wrapperRef}>
          {t('overview.download')}
        </DownloadButton>
      </Row>
      {showEngagementBanner ? (
        <CustomBanner type="hint" withIcon={false} withIconAlign={false} direction="column" onClose={closeBanner}>
          <IconContainer>
            <img src={GoalSettingLight} alt="" />
          </IconContainer>
          <BannerContent>
            <BannerTextContainer>
              <Heading color="primaryDark35">{title}</Heading>
              <Text color="primaryDark35" size="s">
                {description}
              </Text>
            </BannerTextContainer>
            <BannerButton variant="secondary" onClick={closeBanner}>
              {t('overview.engagement.banner_button_label')}
            </BannerButton>
          </BannerContent>
        </CustomBanner>
      ) : null}
    </EngagementBannerContainer>
  );
};

export default EngagementBanner;
