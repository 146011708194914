import { useEffect, useMemo, useRef } from 'react';

import * as d3 from 'd3';
export interface Dimensions {
  width: number;
  height: number;
  margin: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  };
}

function range(start: number, end: number, step = 1) {
  // Guard against invalid step length
  if (step === 0) {
    return [];
  }
  const length = Math.abs((start - end) / step);
  if (Number.isNaN(length)) return [];
  return Array.from(Array(Math.floor(length + 1)), (_, i) => start + i * step);
}

type Data<Keys extends string, X extends string> = {
  [key: string]: string | number;
} & Record<Keys, number> &
  Record<X, string>;

export type KeyField<Keys> = {
  /**
   * Key in the data
   */
  field: Keys;

  /**
   * Which scale to use to plot this data.
   */
  scale: '1' | '2';

  /**
   * Define the data interpolation of the data serie
   */
  type?: 'linear' | 'natural';

  /**
   * Show/hide the area under the path.
   */
  area?: boolean;

  /**
   * Readable value for this field.
   */
  legend?: string;
};

interface Scale {
  /**
   * Format y-axis values
   * @param value value of the axis to be formatted
   * @returns {string} formatted value
   */
  formatLabel?: (value: number) => string;

  /**
   * Show/hide axis
   */
  hidden?: boolean;

  /**
   * Show/hide horizontal grid for this axis
   */
  showGrid?: boolean;

  /**
   * Used for initial value of the axis
   */
  startsFromZero?: boolean;
}

interface LineChartProps<Keys extends string, X extends string> {
  /**
   * List of all fields data with their properties
   */
  readonly keys: KeyField<Keys>[];

  /**
   * The Key used to identify x values
   */
  readonly rangeXKey: X;

  /**
   * Chart's data
   */
  data: Data<Keys, X>[];

  /**
   * Chart's dimensions
   */
  dimensions: Dimensions;

  /**
   * define primary scale properties
   */
  primaryScale?: Scale;

  /**
   * define secondary scale properties
   */
  secondaryScale?: Scale;

  /**
   * list of colort
   * */
  colors?: string[];

  /**
   * Callback function to get the text to show in the tooltip
   */
  tooltipText: (points: Array<{ field: Keys; value: { x: string; y: number } }>) => string;

  /**
   * Value in pixels used for detect closest points.
   * In case of a negative value, it fires an event for all points related to the same x value
   */
  pointThreshold?: number;
}

const DEFAULT_COLORS = ['#2D2F85', '#FF8155', '#97CFFF', '#FEFF86', '#B0DAFF', '#19A7CE', '#146C94'];

function distance(x1: number, x2: number, y1: number, y2: number): number {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
}

export default function LineChart<Keys extends string, X extends string>({
  keys,
  rangeXKey,
  data,
  dimensions,
  primaryScale = {
    hidden: false,
    showGrid: true,
  },
  secondaryScale = {
    hidden: true,
    showGrid: false,
  },
  colors = DEFAULT_COLORS,
  tooltipText,
  pointThreshold = 25,
}: LineChartProps<Keys, X>) {
  const svgRef = useRef<SVGSVGElement>(null);
  const { width = 500, height = 200, margin } = dimensions;
  const [svgWidth, svgHeight] = useMemo(
    () => [width + (margin?.left ?? 0) + (margin?.right ?? 0), height + (margin?.top ?? 0) + (margin?.bottom ?? 0)],
    [height, margin, width]
  );

  const scale1: [number, number] = useMemo(
    () => [
      data
        .flatMap((point) => keys.filter((k) => k.scale === '1').map((k) => point[k.field]))
        .reduce((acc, el) => Math.min(acc, el), primaryScale.startsFromZero ? 0 : Infinity),
      data
        .flatMap((point) => keys.filter((k) => k.scale === '1').map((k) => point[k.field]))
        .reduce((acc, el) => Math.max(acc, el), primaryScale.startsFromZero ? 0 : -Infinity),
    ],
    [data, keys, primaryScale.startsFromZero]
  );

  const scale2: [number, number] = useMemo(
    () => [
      data
        .flatMap((point) => keys.filter((k) => k.scale === '2').map((k) => point[k.field]))
        .reduce((acc, el) => Math.min(acc, el), secondaryScale.startsFromZero ? 0 : Infinity),
      data
        .flatMap((point) => keys.filter((k) => k.scale === '2').map((k) => point[k.field]))
        .reduce((acc, el) => Math.max(acc, el), secondaryScale.startsFromZero ? 0 : -Infinity),
    ],
    [data, keys, secondaryScale.startsFromZero]
  );

  useEffect(() => {
    const svgEl = d3.select(svgRef.current);

    svgEl.selectAll('*').remove(); // Clear svg content before adding new elements
    svgEl.append('rect').attr('width', '100%').attr('height', '100%').attr('fill', 'white');

    let rangeX = data.map((d) => d[rangeXKey]);

    let padding = rangeX.length > 12 ? 4.5 : rangeX.length > 6 ? 1.5 : 1;
    const quarter = Math.ceil((rangeX.length - 1) / 4);
    let x = d3.scaleBand().range([0, width]);
    x.domain(rangeX).padding(padding);
    const svg = svgEl.append('g').attr('transform', `translate(${margin.left},${margin.top})`);
    svg
      .append('g')
      .attr('transform', `translate(0, ${height + 5})`)
      .call(
        d3.axisBottom(x).tickValues(
          rangeX.filter((r, i) => {
            if (rangeX.length < 8) return true;
            return i === 0 || i === quarter || i === rangeX.length - 1 - quarter || i === rangeX.length - 1;
          })
        )
      );

    const rangeY1: number[] = range(scale1[0], scale1[1], scale1[1] * 0.2);
    const rangeY2: number[] = range(scale2[0], scale2[1], scale2[1] * 0.2);

    if (rangeY1.length < rangeY2.length) {
      rangeY1.push(scale1[scale1.length - 1]);
    }

    let yScale = d3.scaleLinear().domain(scale1).range([height, 0]);

    let y = d3.axisLeft<number>(yScale).tickValues(rangeY1);
    if (primaryScale.formatLabel) {
      y.tickFormat(primaryScale.formatLabel);
    }

    let yScale2 = d3.scaleLinear().domain(scale2).range([height, 0]);

    let y2 = d3.axisRight<number>(yScale2).tickValues(rangeY2);
    if (secondaryScale.formatLabel) {
      y2.tickFormat(secondaryScale.formatLabel);
    }

    if (!primaryScale.hidden) {
      svg.append('g').attr('transform', `translate(50, -8)`).attr('class', 'axis-y').call(y);
    }

    if (!secondaryScale.hidden) {
      svg
        .append('g')
        .attr('transform', `translate(${width - 50}, -10)`)
        .attr('class', 'axis-y2')
        .call(y2);
    }

    if (primaryScale.showGrid) {
      const g = svg.append('g').attr('data-name', 'primay-grid');
      for (const i of rangeY1) {
        g.append('line')
          .style('stroke', '#DDDEE9')
          .style('stroke-width', 1.5)
          .attr('x1', 50)
          .attr('y1', yScale(i))
          .attr('x2', width - 50)
          .attr('y2', yScale(i));
      }
    }

    if (secondaryScale.showGrid) {
      const g = svg.append('g').attr('data-name', 'secondary-grid');
      for (const i of rangeY2) {
        g.append('line')
          .style('stroke', '#DDDEE9')
          .style('stroke-width', 1.5)
          .attr('x1', 50)
          .attr('y1', yScale2(i))
          .attr('x2', width - 50)
          .attr('y2', yScale2(i));
      }
    }

    svg.selectAll('.tick line').remove();
    svg.selectAll('.domain').remove();
    svg
      .selectAll('.tick text')
      .style('font-size', '15px')
      .style('fill', '#51537B')
      .attr('font-family', 'Montserrat')
      .attr('font-size', '12px')
      .attr('font-weight', '400')
      .attr('line-height', '18px')
      .attr('letter-spacing', '0px')
      .attr('text-align', 'left')
      .attr('alignment-baseline', 'middle');

    svg
      .append('line')
      .style('stroke', '#DDDEE9')
      .style('stroke-width', 1.5)
      .attr('x1', 50)
      .attr('y1', height)
      .attr('x2', width - 50)
      .attr('y2', height);

    // Compute labels width
    const labelsWidth = d3.sum(svg.selectAll('.axis-x .tick text'), (el) => (el as SVGTSpanElement).getBBox().width);
    // Detect if labels overlaps
    if (labelsWidth > dimensions.width) {
      // Compute the number of labels to remove
      const step = Math.ceil(labelsWidth / dimensions.width);
      // Keep a text each `step` ones
      svg
        .selectAll('.axis-x .tick')
        .filter((_, idx) => idx % step !== 0)
        .remove();
    }

    // Define the tool tip box
    let tip = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip')
      .style('display', 'none')
      .style('padding', '10px')
      .style('background-color', '#313391')
      .style('color', 'white')
      .style('position', 'absolute')
      .style('z-index', '1')
      .style('user-select', 'none')
      .style('border-radius', '5px')
      .style('font-size', '15px')
      .style('fill', '#51537B')
      .style('font-family', 'Montserrat')
      .style('font-size', '12px')
      .style('font-weight', '400')
      .style('line-height', '18px')
      .style('letter-spacing', '0px')
      .style('text-align', 'left')
      .style('alignment-baseline', 'middle');

    // Hide tooltip when mouseout
    tip.on('mouseout', function () {
      tip.style('display', 'none');
    });

    keys.forEach((key, idx) => {
      const g = svg.append('g').attr('data-name', key.field);
      const nullFiltered = data.filter((d) => d[key.field] !== null);
      if (key.area) {
        g.append('path')
          .datum(nullFiltered)
          .attr('data-name', 'area')
          .attr('fill', colors[idx % colors.length])
          .attr('fill-opacity', 0.1)
          .attr('stroke', 'none')
          .attr(
            'd',
            d3
              .area<Data<Keys, X>>()
              .curve(key.type === 'natural' ? d3.curveNatural : d3.curveLinear)
              .x((d) => x(d[rangeXKey])!)
              .y0(height)
              .y1((d) => (key.scale === '1' ? yScale(d[key.field]) : yScale2(d[key.field])))
          );
      }

      g.append('path')
        .datum(nullFiltered)
        .attr('fill', 'none')
        .attr('data-name', 'line')
        .attr('stroke', colors[idx % colors.length])
        .attr('stroke-width', 1.5)
        .attr(
          'd',
          d3
            .line<Data<Keys, X>>()
            .curve(key.type === 'natural' ? d3.curveNatural : d3.curveLinear)
            .x((d) => x(d[rangeXKey])!)
            .y((d) => (key.scale === '1' ? yScale(d[key.field]) : yScale2(d[key.field])))
        );

      // Group points
      g.append('g')
        .attr('data-name', 'points')
        .selectAll('myCircles')
        .data(nullFiltered)
        .enter()
        // Append a circle for each point
        .append('circle')
        .attr('fill', colors[idx % colors.length])
        .attr('stroke', 'none')
        .attr('cx', (d) => x(d[rangeXKey])!)
        .attr('cy', (d) => (key.scale === '1' ? yScale(d[key.field]) : yScale2(d[key.field])))
        .attr('r', 5);
    });

    // Add a rect to monitor mouse movement.
    // I need it to look for each near point.
    // This element should be attached to the main svg element
    svgEl
      .append('rect')
      .attr('data-name', 'mouse-tracker')
      .attr('fill', 'transparent')
      .attr('width', '100%')
      .attr('height', '100%')
      // I need to use absolute positioning in order to use z-index and position
      // this react hover the tooltip
      .style('position', 'absolute')
      .style('z-index', '10')
      .on('mousemove', function (e) {
        // Convert mouse coordinates to chart coordinates
        let [mx, my] = d3.pointer(e);

        // I need to compensate the translation applied to the chart
        mx -= margin.left;
        my -= margin.top;

        // I look for the x bucket. Empirical tests show that bucket values
        // are between 1 and data.length.
        const bucket = Math.round(mx / x.step());
        const xValue = data[bucket - 1]?.[rangeXKey];

        const points = data
          .flatMap((datum) =>
            keys.map((key) => ({
              field: key.field,
              value: { x: datum[rangeXKey], y: datum[key.field] },
              x: x(datum[rangeXKey])!,
              y: key.scale === '1' ? yScale(datum[key.field]) : yScale2(datum[key.field]),
            }))
          )
          .filter((p) => {
            // If we have a threshold, we look for closest point based on the point distance
            if (pointThreshold > 0) {
              return distance(mx, p.x, my, p.y) < pointThreshold;
            }
            // Otherwise, we check if the point is in the bucket
            return p.value.x === xValue;
          });

        if (points.length) {
          tip
            .style('display', 'block')
            .html(tooltipText(points))
            .style('left', e.pageX - 80 + 'px')
            .style('top', e.pageY - 80 + 'px');
        } else {
          tip.style('display', 'none');
        }
      })
      .on('mouseout', function (e) {
        // If tooltip is too large, it can overlap to the current pointer,
        // so I need to check that case and prevent the tooltip to be closed.
        if (e.relatedTarget?.className === 'tooltip') {
          // Over tooltip => ignore
          return;
        }
        // Hide tooltip
        tip.style('display', 'none');
      });
    tip.on('mouseout', () => {
      tip.style('display', 'none');
    });

    let currentX = 18;

    let currentY = height + 50;

    const legendsContainer = svg.append('g').attr('data-name', 'legends');
    keys.forEach((key, index) => {
      const g = legendsContainer.append('g').attr('data-name', key.field);
      const circle = g
        .append('circle')
        .attr('cx', currentX)
        .attr('cy', currentY)
        .attr('r', 6)
        .style('fill', colors[index % colors.length]);

      const line = g
        .append('line')
        .style('stroke', colors[index % colors.length])
        .style('stroke-width', 2)
        .attr('x1', currentX - 12)
        .attr('y1', currentY)
        .attr('x2', currentX + 12)
        .attr('y2', currentY);

      const text = g
        .append('text')
        .attr('x', currentX + 18)
        .attr('y', currentY)
        .text(key.legend ?? key.field)
        .style('font-size', '15px')
        .style('fill', '#81839C')
        .attr('font-family', 'Montserrat')
        .attr('font-size', '12px')
        .attr('font-weight', '400')
        .attr('line-height', '16px')
        .attr('letter-spacing', '0px')
        .attr('text-align', 'left')
        .attr('alignment-baseline', 'middle');

      currentX = currentX + 38 + (text.node()?.getBBox().width ?? 0);

      if (currentX > width) {
        currentY = currentY + 15;
        currentX = 18;

        circle.attr('cx', currentX).attr('cy', currentY);
        line
          .attr('x1', currentX - 12)
          .attr('y1', currentY)
          .attr('x2', currentX + 12)
          .attr('y2', currentY);
        text.attr('x', currentX + 18).attr('y', currentY);
        currentX = currentX + 38 + (text.node()?.getBBox().width ?? 0);
      }
    });
  }, [
    data,
    colors,
    height,
    keys,
    margin,
    pointThreshold,
    primaryScale,
    scale1,
    scale2,
    secondaryScale,
    rangeXKey,
    tooltipText,
    width,
    dimensions,
  ]);

  return <svg ref={svgRef} width={svgWidth} height={svgHeight} />;
}
