import { useEffect, useMemo, useState } from 'react';

import { Pagination } from '@coachhubio/nova-pagination';
import { Skeleton } from '@coachhubio/nova-skeleton';
import { Text } from '@coachhubio/nova-text';
import { useLooker } from 'context/LookerContext';
import { useGetLookerSortedFeedbackData } from 'hooks/useGetLookerData';
import { useTranslation } from 'react-i18next';
import { LookerFieldsTag } from 'screens/Dashboard/utils/lookerFields';

import FeedbackTableItem, { FeedbackItem } from './CoacheeFeedbackTableItem';
import { FeedbackPlaceholder, ListCol } from './Styles';

type FeedbackTableProps = {
  itemsPerPage?: number;
} & { [dataAttribute: `data-${string}`]: string };

export default function FeedbackTable({ itemsPerPage = 10, ...props }: FeedbackTableProps) {
  const { t } = useTranslation('Dashboard');

  const { loading, data } = useGetLookerSortedFeedbackData<FeedbackItem[]>(LookerFieldsTag.SatisfactionFeedback);

  const { score, sorts } = useLooker();

  const [pageNumber, updatePage] = useState(1);

  const page = useMemo(() => {
    if (Array.isArray(data) && data.length > 0) {
      const pageIndexStart = (pageNumber - 1) * itemsPerPage;
      const pageIndexEnd = pageIndexStart + itemsPerPage;
      return data.slice(pageIndexStart, pageIndexEnd);
    }
    return null;
  }, [data, pageNumber, itemsPerPage]);

  useEffect(() => {
    //Reset pagination on filter update
    updatePage(1);
  }, [score, sorts]);

  return (
    <ListCol {...props}>
      {!loading ? (
        page ? (
          <>
            {page.map((feedback, index) => (
              <FeedbackTableItem
                data-testid="coachee-feedback-item"
                feedback={feedback}
                key={`${feedback['coachee_sessions.startsat_date']}-${index}`}
              />
            ))}
            <Pagination
              data-testid="coachee-feedback-pagination"
              totalItems={data?.length ?? 0}
              page={pageNumber}
              itemsPerPage={itemsPerPage}
              withItemsPerPageSelect={false}
              onChange={(currentPage) => {
                updatePage(currentPage);
              }}
              i18n={{
                currentlyVisible: t('satisfaction.feedback.pagination'),
                goTo: t('satisfaction.feedback.goTo'),
              }}
            />
          </>
        ) : (
          <Text color="primaryDark35" size="xl" type="heading">
            {t(`common.no_data`)}
          </Text>
        )
      ) : (
        [...Array(itemsPerPage).keys()].map((_, i) => (
          <FeedbackPlaceholder data-testid="placeholder" $height="116px" $width="100%" key={`${i}`}>
            <Skeleton height="116px" width="100%" />
          </FeedbackPlaceholder>
        ))
      )}
    </ListCol>
  );
}
