import { ColorAlias, getBorderColor } from '@coachhubio/nova-colors';
import { Text } from '@coachhubio/nova-text';
import { FontSizeToken } from '@coachhubio/nova-typography';
import styled from 'styled-components';

const Progress = styled.div`
  width: 100px;
  height: 100px;
  background: none;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Span = styled.span`
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
`;

const ProgressLeft = styled(Span)`
  left: 0;
`;

const ProgressRight = styled(Span)`
  right: 0;
`;

const ProgressBar = styled(Span)`
  width: 100%;
  height: 100%;
  background: none;
  border-width: 8px;
  border-style: solid;
  position: absolute;
  top: 0;
`;

const ProgressBarLeft = styled(ProgressBar)`
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
  transition: transform 1.2s ease-in-out;
  transition-delay: 1.2s;
`;

const ProgressBarRight = styled(ProgressBar)`
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  transition: transform 1.2s ease-in-out;
`;

const ProgressColor = styled(Progress)<{ $bgColor: ColorAlias; $size: string }>`
  ${({ $size }) => `height: ${$size};`}
  ${({ $size }) => `width: ${$size};`}
  &:after {
    border: 8px solid;
    ${({ $bgColor }) => getBorderColor($bgColor)};
  }
`;

const ProgressBarLeftTransform = styled(ProgressBarLeft)<{ $transform: string; $color: ColorAlias }>`
  ${({ $color }) => getBorderColor($color as any)};
  ${({ $transform }) => $transform};
`;

const ProgressBarRightTransform = styled(ProgressBarRight)<{ $transform: string; $color: ColorAlias }>`
  ${({ $color }) => getBorderColor($color as any)};
  ${({ $transform }) => $transform};
`;

const ProgressValue = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

type CircularProgressProps = {
  value: number;
  min?: number;
  max?: number;
  size?: string;
  bgColor: ColorAlias;
  color: ColorAlias;
  fontSize?: FontSizeToken;
  valueFormatter?: string;
  [dataAttribute: `data-${string}`]: string;
};

function percentageToDegrees(percentage: number): number {
  const degrees = (percentage / 100) * 360;
  return degrees > 180 ? 180 : degrees;
}

export function CircularProgress({
  value,
  min = 0,
  max = 100,
  bgColor,
  color,
  size = '100px',
  fontSize = 'l',
  valueFormatter,
  ...props
}: CircularProgressProps) {
  let transformRight = ``;
  let transformLeft = ``;

  if (value > min) {
    const new_cal: number = (value * 100) / max;
    if (new_cal <= 50) {
      transformRight = 'transform: rotate(' + percentageToDegrees(new_cal) + 'deg);';
    } else {
      transformRight = 'transform: rotate(180deg);';
      transformLeft = 'transform: rotate(' + percentageToDegrees(new_cal - 50) + 'deg);';
    }
  }

  return (
    <ProgressColor $bgColor={bgColor} $size={size as string} {...props}>
      <ProgressLeft>
        <ProgressBarLeftTransform $transform={transformLeft} $color={color} />
      </ProgressLeft>
      <ProgressRight>
        <ProgressBarRightTransform $transform={transformRight} $color={color} />
      </ProgressRight>
      <ProgressValue>
        <Text color={color} size={fontSize} modifier="bold">
          {valueFormatter}
        </Text>
      </ProgressValue>
    </ProgressColor>
  );
}
