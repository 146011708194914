import React, { useState, useRef, useMemo } from 'react';

import { borderRadius } from '@coachhubio/nova-borders';
import { getAliasColorValue, getBackgroundColor } from '@coachhubio/nova-colors';
import { Text } from '@coachhubio/nova-text';
import { Tooltip } from '@coachhubio/nova-tooltip';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import i18n from 'translations/i18n';

import { Row, Column } from '../../components/Common';

const BarWrapper = styled.div`
  position: relative;
  width: 100%;
  min-width: 250px;
  height: 16px;

  ${getBackgroundColor('neutral95')}
  ${borderRadius('m')}
`;

const DarkBar = styled.div<{ $size: number }>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  ${({ $size }) => `right: ${100 - $size}%;`}

  ${getBackgroundColor('primaryDark70')}
  ${borderRadius('m')}
`;

const LightBar = styled(DarkBar)`
  ${getBackgroundColor('primaryDark85')}
`;

const Goal = styled.div<{ $value: number }>`
  position: absolute;
  top: -5px;
  bottom: -5px;
  width: 0px;

  border: 2px solid ${getAliasColorValue('success35')};
  ${borderRadius('m')}

  ${({ $value }) => `right: ${100 - $value}%;`};
`;

const TooltipRow = styled(Row)`
  justify-content: space-between;

  gap: 40px;
`;

function getNumberFormatter(language: string, digits: number = 0) {
  return new Intl.NumberFormat(language.replace('_', '-'), {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
}

const ProgressGoalsBar = ({
  goalRating,
  firstRating,
  lastRating,
  maxRating = 10,
}: {
  goalRating: number;
  firstRating: number;
  lastRating: number;
  maxRating?: number;
}) => {
  const ratio = 100 / maxRating;
  const { t } = useTranslation('Dashboard');
  const tooltipParentRef = useRef<HTMLDivElement | null>(null);

  const [active, setActive] = useState(false);

  const handleHover = (val: boolean) => {
    return () => setActive(val);
  };

  const numberFormatter = useMemo(() => getNumberFormatter(i18n.language, 1), []);

  return (
    <BarWrapper
      ref={tooltipParentRef}
      onMouseEnter={handleHover(true)}
      onMouseLeave={handleHover(false)}
      data-testid="progress-goals"
    >
      <DarkBar $size={lastRating * ratio} />
      <LightBar $size={firstRating * ratio} />
      <Goal $value={goalRating * ratio} />
      <Tooltip active={active} parentRef={tooltipParentRef.current} placement="bottom" variant="dark" withArrow>
        <Column>
          <TooltipRow>
            <Text size="s" color="neutral">
              {t('impact.progress.focusarea.legend.initial')}
            </Text>
            <Text size="s" modifier="bold" color="neutral">
              {numberFormatter.format(firstRating)}
            </Text>
          </TooltipRow>
          <TooltipRow>
            <Text size="s" color="neutral">
              {t('impact.progress.focusarea.legend.latest')}
            </Text>
            <Text size="s" color="neutral" modifier="bold">
              {numberFormatter.format(lastRating)}
            </Text>
          </TooltipRow>
          <TooltipRow>
            <Text size="s" color="neutral">
              {t('impact.progress.focusarea.legend.goal')}
            </Text>
            <Text size="s" color="neutral" modifier="bold">
              {numberFormatter.format(goalRating)}
            </Text>
          </TooltipRow>
        </Column>
      </Tooltip>
    </BarWrapper>
  );
};

export default ProgressGoalsBar;
