import React from 'react';

import { Row } from 'screens/Dashboard/components/Common';

import CoacheeFeedbackDownload from './CoacheeFeedbackDownload';
import CoacheeFeedbackFilters from './CoacheeFeedbackFilters';
import CoacheeFeedbackHeader from './CoacheeFeedbackHeader';
import CoacheeFeedbackTable from './CoacheeFeedbackTable';

const itemsPerPage = 6;

const CoacheeFeedback = () => {
  return (
    <>
      <CoacheeFeedbackHeader data-testid="coachee-feedback-header" />
      <Row>
        <CoacheeFeedbackFilters data-testid="coachee-feedback-filters" />
        <CoacheeFeedbackDownload data-testid="coachee-feedback-download" />
      </Row>
      <CoacheeFeedbackTable data-testid="coachee-feedback-table" itemsPerPage={itemsPerPage} />
    </>
  );
};

export default CoacheeFeedback;
