import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { useQuery } from '@apollo/client';
import { GET_ORGANIZATION_QUERY } from 'apollo/organization-api/organization';
import { getI18n } from 'react-i18next';
import { fetchApi } from 'utils/fetchApi';
import { FiltersData } from 'utils/fetchDashboard';
import { currentTenant } from 'utils/multitenant';

import { useUser } from '../context/AuthContext';

const language = getI18n()?.language || 'en_GB';

export type LookerConfig = {
  looks: {
    ['hr_dashboard_looks_list.look_id']: string;
    ['hr_dashboard_looks_list.look_name']: string;
    ['hr_dashboard_looks_list.updated_at_date']: string;
  }[];
  token: {
    access_token: string;
    expires_in: number;
    refresh_token: null | string;
    token_type: string;
  };
};

const getLookerConfig = async function (
  userId: string,
  orgId: string,
  locale: string,
  page: string
): Promise<LookerConfig> {
  try {
    const response = await fetchApi('/api/dashboard/initialization', {
      method: 'POST',
      body: JSON.stringify({
        userId,
        orgId,
        locale,
        page,
      }),
    });
    return await response.json();
  } catch (e) {
    console.error(e);
    throw new Error('Looker Config empty');
  }
};

export type ILooker = {
  looker: LookerConfig | undefined;
  filters: FiltersData | undefined;
  sorts: string[] | undefined;
  score: string[] | undefined;
  updateScore: Dispatch<SetStateAction<string[] | undefined>>;
  updateSorts: Dispatch<SetStateAction<string[] | undefined>>;
  updateFiltersAPI: Dispatch<SetStateAction<FiltersData | undefined>>;
  organizationData: any;
};

type useInitializeLookerProps = {
  page: string;
};

const useInitializeLooker = ({ page }: useInitializeLookerProps): ILooker => {
  const user = useUser();
  const [filtersAPI, updateFiltersAPI] = useState<FiltersData | undefined>(undefined);
  const [lookerConfig, setLookerConf] = useState<LookerConfig | undefined>(undefined);
  const [score, updateScore] = useState<string[] | undefined>(undefined);
  const [sorts, updateSorts] = useState<string[] | undefined>(undefined);

  const { data, refetch } = useQuery(GET_ORGANIZATION_QUERY, {
    variables: { organizationId: user.organizationId },
    context: { clientName: currentTenant(user.tenantId) },
  });
  const { getOrganization: organizationData } = data || { getOrganization: null };

  // We need to prevent the localStorage to be cleared on page load, otherwise we lose the benefits
  // of storing the filters into the localStorage
  const emptyLocalStorage = useRef<boolean>();

  useEffect(() => {
    // the first time, emptyLocalStorage.current is undefined, that is a falsy value
    if (emptyLocalStorage.current) {
      // Reset filters in case that the organization changes.
      window.localStorage.removeItem('dashboardFilters');
      updateFiltersAPI(undefined);
      setLookerConf(undefined);
      updateSorts(undefined);
    } else {
      // update the reference value to force change
      emptyLocalStorage.current = true;
    }
    refetch({ organizationId: user.organizationId });
  }, [refetch, user.organizationId, user.tenantId]);

  useEffect(() => {
    const initializeLooker = async (userId: string, organizationId: string, language: string, page: string) => {
      const data = await getLookerConfig(userId, organizationId, language, page);

      if (data?.token?.token_type && data?.token?.access_token) {
        setLookerConf(data);
      } else {
        setLookerConf(undefined);
      }
    };

    if (user?.id && organizationData && !lookerConfig) {
      initializeLooker(user.id, user.organizationId, language, page);
    }
  }, [organizationData, lookerConfig, user.id, user.organizationId, page]);

  return {
    looker: lookerConfig,
    filters: filtersAPI,
    updateFiltersAPI,
    organizationData,
    sorts,
    updateSorts,
    score,
    updateScore,
  };
};

export default useInitializeLooker;
