import { useMemo } from 'react';

import { InfoIcon } from '@coachhubio/nova-icon';
import { Skeleton } from '@coachhubio/nova-skeleton';
import { Tag } from '@coachhubio/nova-tag';
import { Text } from '@coachhubio/nova-text';
import DOMPurify from 'dompurify';
import useGetLookerData from 'hooks/useGetLookerData';
import { useLocale } from 'hooks/useLocale';
import ReactHtmlParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { BrandTag, Card, Placeholder, Row } from 'screens/Dashboard/components/Common';
import { LookerFieldsTag } from 'screens/Dashboard/utils/lookerFields';

import { ColumnWithGap, HappyIcon, SadIcon } from './Styles';

type Rating = {
  'coachee_sessions.m_avg_session_ratings': number;
  'benchmark_fixed_period.benchmark_value': number;
};

type SessionRatingProps = {} & { [dataAttribute: `data-${string}`]: string };

export default function SessionRating(props: SessionRatingProps) {
  const { t } = useTranslation('Dashboard');
  const locale = useLocale();

  const { loading, data } = useGetLookerData<Rating[]>(LookerFieldsTag.SatisfactionSessionRating);

  const [rating, benchmark] = useMemo(() => {
    if (Array.isArray(data) && data.length > 0) {
      const { 'coachee_sessions.m_avg_session_ratings': rating, 'benchmark_fixed_period.benchmark_value': benchmark } =
        data[0] ?? {};
      return [rating, benchmark].map((el) => (['number', 'string'].includes(typeof el) ? Number(el.toFixed(1)) : null));
    }
    return [null, null];
  }, [data]);

  return (
    <Card {...props}>
      <ColumnWithGap>
        <Text color="primaryDark35" size="xl" type="heading">
          <Row>
            {!loading ? (
              Number.isFinite(rating) ? (
                <>
                  {`${new Intl.NumberFormat(locale).format(rating!)}/5`}
                  {rating! >= 4 ? <HappyIcon data-testid="icon-happy" /> : <SadIcon data-testid="icon-sad" />}
                </>
              ) : (
                <Text color="primaryDark35" size="xl" type="heading">
                  {t(`common.no_data`)}
                </Text>
              )
            ) : (
              <Placeholder data-testid="placeholder" $height="42px" $width="120px">
                <Skeleton height="42px" width="120px" />
              </Placeholder>
            )}
          </Row>
        </Text>
        <Text size="s" modifier="bold" color="primaryDark25">
          {t('overview.satisfaction.avg_session.description')}
        </Text>
        {!loading ? (
          Number.isFinite(benchmark) ? (
            // TODO(looker): replace satisBenchmark with the correct data
            <BrandTag>
              {t('overview.satisfaction.avg_session.benchmark', {
                number: new Intl.NumberFormat(locale).format(benchmark!),
              })}
            </BrandTag>
          ) : (
            <Tag variant="hint">{t(`common.no_data`)}</Tag>
          )
        ) : (
          <Placeholder data-testid="placeholder" $height="16px" $width="70px">
            <Skeleton height="16px" width="70px" />
          </Placeholder>
        )}
        <Tag iconPosition="start" variant="hint" Icon={InfoIcon}>
          {t('overview.satisfaction.avg_session.benchmark_info')}
        </Tag>
        <Text color="primaryDark25" size="s">
          {!loading ? (
            Number.isFinite(rating) ? (
              ReactHtmlParser(
                DOMPurify.sanitize(
                  t(`overview.satisfaction.avg_session.insight.${rating! >= 4 ? 'positive' : 'negative'}`)
                )
              )
            ) : (
              <Tag variant="hint">{t(`common.no_data`)}</Tag>
            )
          ) : (
            <Placeholder data-testid="placeholder" $height="60px" $width="100%">
              <Skeleton height="60px" width="100%" />
            </Placeholder>
          )}
        </Text>
      </ColumnWithGap>
    </Card>
  );
}
