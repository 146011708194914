import React, { RefObject, createContext, createRef } from 'react';

type ICoachingLicenses = {
  ref: RefObject<HTMLDivElement>;
};

const CoachingLicensesContext = createContext<ICoachingLicenses | undefined>(undefined);
const coachingLicensesRef = createRef<HTMLDivElement>();

type CoachingLicensesRef = {
  children: React.ReactElement;
};

function CoachingLicensesProvider({ children }: CoachingLicensesRef) {
  return (
    <CoachingLicensesContext.Provider value={{ ref: coachingLicensesRef }}>{children}</CoachingLicensesContext.Provider>
  );
}

function useCoachingLicensesRef() {
  const context = React.useContext(CoachingLicensesContext);
  if (context === undefined) {
    throw new Error('useLooker must be used within a CoachingLicensesProvider');
  }
  return context;
}

export { CoachingLicensesProvider, useCoachingLicensesRef };
