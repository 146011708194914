import React from 'react';

import { LightBulbIcon } from '@coachhubio/nova-icon';
import { Skeleton } from '@coachhubio/nova-skeleton';
import { Text } from '@coachhubio/nova-text';
import DOMPurify from 'dompurify';
import ReactHtmlParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const LightBulbIconContainer = styled.div`
  & svg {
    width: 32px;
    height: 32px;
  }
`;

type Props = {
  isLoading: boolean;
  i18overviewDescription?: string;
};
const SessionScoreBarOverview = ({ isLoading, i18overviewDescription = '' }: Props) => {
  const { t } = useTranslation('Dashboard');

  return (
    <>
      <LightBulbIconContainer>
        <LightBulbIcon color="brandDark" />
      </LightBulbIconContainer>
      {isLoading ? (
        <Skeleton height="60px" width="100%" />
      ) : i18overviewDescription ? (
        <Text color="text30" size="s">
          {ReactHtmlParser(DOMPurify.sanitize(t(i18overviewDescription)))}
        </Text>
      ) : (
        <Text color="text30" size="s">
          {t(`common.no_data`)}
        </Text>
      )}
    </>
  );
};

export default SessionScoreBarOverview;
